<template>
  <div class="w-100 d-flex flex-column px-4 pb-3 pt-4">
    <div class="w-100 pb-4">
      <h5>
        {{ $t('my-account.views.plan.actual-plan-panel.subtitle') }}
      </h5>
    </div>
    <FeaturesListing :members-free="membersFeaturesFree" :funnels-free="funnelsFeaturesFree" use-icon />
  </div>
</template>
<script>
import FeaturesListing from './FeaturesListing';

const FEATURE_ICONS = {
  funnels: ['funnel-dollar', 'envelope', 'users'],
  members: ['box-full', 'piggy-bank', 'usd-circle'],
};
export default {
  components: { FeaturesListing },
  data() {
    return {
      funnelsFeaturesFree: new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-free-features.${index}`),
        icon: FEATURE_ICONS.funnels[index],
      })),
      membersFeaturesFree: new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.members-free-features.${index}`),
        icon: FEATURE_ICONS.members[index],
      })),
    };
  },
};
</script>
