
































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import PageHeader from '@/components/_structures/PageHeader.vue';
import ToasterHelper from '@/shared/helpers/toast';
import { userService } from '@/services';
import MButton from '@/shared/components/MButton.vue';
import { MyTeamUser } from '@/types/myteam';
import { User } from '@/types/user';

const AuthModule = namespace('auth');

@Component({
  components: {
    PageHeader,
    MButton,
    MyTeamSidebar: () => import(/* webpackChunkName: "myTeamSidebar" */ './components/MyTeamSidebar.vue'),
    ModalInvite: () => import(/* webpackChunkName: "modalInvite" */ './components/ModalInvite.vue'),
    Loading: () => import(/* webpackChunkName: "loading" */ '@/components/Loading.vue'),
  },
})
export default class MyTeam extends Vue {
  @AuthModule.Action getRoles!: any;

  search: string = '';
  showSidebar: boolean = false;
  editUser: boolean = false;
  users: MyTeamUser[] = [];
  selectedUser: MyTeamUser | null = null;
  showModalInvite: boolean = false;
  isLoading: boolean = false;
  debounceSearch: number = 2;
  currentPage: number = 1;
  perPage: number = 11;
  usersLength: number = 0;
  usersLengthToShowSearch: number = 9;

  async mounted() {
    this.getUsers();
    await this.getSchoolRoles();
  }

  get showPagination(): boolean {
    return this.usersLength > this.perPage && this.search.length < this.debounceSearch;
  }

  get showSearch() {
    return this.usersLength >= this.usersLengthToShowSearch;
  }

  get usersPerPage(): MyTeamUser[] {
    return this.users.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
  }

  get filteredUsers(): MyTeamUser[] {
    if (this.search !== '' && this.search.length > this.debounceSearch) {
      return this.users.filter(user => {
        return user.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }

    return this.usersPerPage;
  }

  checkRoute(route: any) {
    return route == 'RolesAndPermissions';
  }

  async getSchoolRoles() {
    await this.getRoles();
  }

  async getUsers() {
    try {
      this.isLoading = true;
      this.showSidebar = false;
      this.users = [];
      const { data } = await userService.getUsers([ { key: 'per_page', value: 50 },{ key: 'page', value: this.currentPage }]);
      this.removeSuportUserAndMountUser(data.collaborators);
    } catch (error) {
      ToasterHelper.dangerMessage(this.$t('my-account.views.my-team.toast-error-users-list'));
    } finally {
      this.isLoading = false;
    }
  }

  removeSuportUserAndMountUser(users: User[]) {
    let totalUsers = 0;
    users.forEach((user: User) => {
      if (user.email !== 'contato@edools.com') {
        totalUsers++;

        this.users.push({
          id: user.id,
          name: user.last_name ? `${user.first_name} ${user.last_name}` : user.first_name,
          email: user.email,
          cover_image_url: user.cover_image_url,
          created_at: dayjs(user.created_at).format('DD/MM/YYYY'),
          updated_at: this.lastAccess(user),
          role: {id: user.roles[0].id, name: user.roles[0].name},
        });
      }
    });

    this.usersLength = totalUsers;
  }

  lastAccess(user: User): string {
    const created_at = dayjs(user.created_at).format('DD/MM/YYYY');
    const updated_at = dayjs(user.updated_at).format('DD/MM/YYYY');
    if (created_at === updated_at) {
      return String(this.$t('my-account.views.my-team.invite-card.no-registry'));
    }
    return dayjs(user.updated_at).format('DD/MM/YYYY');
  }

  toggleSidebar(user: MyTeamUser) {
    const lastSelectedUser: MyTeamUser | null = this.selectedUser;

    if (this.showSidebar && lastSelectedUser?.id === user.id) {
      this.showSidebar = false;
      this.selectedUser = null;
    } else {
      this.selectedUser = user;
      this.showSidebar = true;
    }
  }

  hideSidebar() {
    this.showSidebar = false;
  }

  openModalInvite(editUser: boolean) {
    this.showModalInvite = true;
    this.isEditingOrCreatingUser(editUser);
  }

  isEditingOrCreatingUser(editUser: boolean) {
    if (editUser) {
      this.editUser = true;
    } else {
      this.editUser = false;
      this.selectedUser = null;
      this.showSidebar = false;
    }
  }

  closeModalInvite(event?: any) {
    this.showModalInvite = false;
    event?.reloadUsers && this.getUsers();
  }
}
