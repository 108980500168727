<template>
  <div class="w-100 d-flex flex-column px-4 pb-3 pt-4 justify-content-between">
    <div class="w-100 pb-4">
      <h5>
        {{ $t('my-account.views.plan.actual-plan-panel.subtitle') }}
      </h5>
    </div>
    <FeaturesListing :funnels-premium="funnelsFeaturesPremium" />
  </div>
</template>

<script>
import FeaturesListing from './FeaturesListing';
export default {
  components: { FeaturesListing },
  computed: {
    funnelsFeaturesPremium() {
      return new Array(6).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-basic-features.${index}`),
      }));
    },
  },
};
</script>
