<template>
  <div id="NextPlanBanner" data-testid="next-plan-banner" class="bg-white rounded-lg overflow-hidden">
    <div class="d-flex flex-wrap flex-lg-row p-4">
      <div class="d-flex col-lg-9 px-0">
        <div class="d-none d-lg-block mr-3">
          <img class="hs-next-plan-banner__img w-100" />
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-wrap pb-2">
            <h4 class="mr-2">{{ $t('my-account.views.plan.title') }}</h4>
            <h4 class="font-weight-bold mr-2">
              {{ plan.title }}
            </h4>
            <div v-if="plan.highlighted">
              <span class="hs-next-plan__recommended-badge d-none d-lg-block px-2 text-cyan font-weight-bold">
                {{ $t('my-account.views.plan.recommended') }}
              </span>
            </div>
          </div>
          <div>
            {{ plan.content }}
          </div>
          <div>
            <a
              data-id="open-modal"
              role="button"
              class="pointer btn py-2 pl-0 text-primary"
              @click="planDetailsModalVisible = true"
            >
              {{ $t('my-account.views.plan.see-all-features') }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-3 px-0 d-flex justify-content-between justify-content-lg-end align-items-center mt-2 mt-md-0"
      >
        <img class="d-block d-lg-none" width="100" />
        <div class="d-flex flex-column justify-content-center justify-content-center">
          <div class="hs-text-grey-30" v-if="price">
            <span class="font-weight-bold hs-text-20"> {{ price }} </span>
            <span> /{{ $t('my-account.views.plan.month') }} </span>
          </div>
          <b-button
            data-id="choose-plan-btn"
            class="mt-1 mt-lg-2"
            variant="primary"
            target="_blank"
            @click="onChoosePlan"
          >
            {{ $t('my-account.views.plan.next-plan-banner.choose-plan') }}
          </b-button>
        </div>
      </div>
      <PlanDetailsModal
        data-id="modal"
        :visible="planDetailsModalVisible"
        :plan="plan"
        @close="planDetailsModalVisible = false"
        @pick="onPlanPick"
        show-plan-pick-button
      />
    </div>
  </div>
</template>
<script>
import { analyticsService } from '@/services';
import PlanDetailsModal from './PlanDetailsModal/index';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import GeneralHelper from '@/shared/helpers/general';
import ZendeskService from '@/services/zendesk';
import Tracking from '@/shared/helpers/tracking';

dayjs.extend(isBetween);

export default {
  components: { PlanDetailsModal },
  props: {
    plan: Object,
  },
  data() {
    return {
      planDetailsModalVisible: false,
    };
  },
  computed: {
    price() {
      return this.plan.price ? GeneralHelper.currency(this.plan.price) : null;
    },
  },
  methods: {
    onPlanPick() {
      this.planDetailsModalVisible = false;
      this.$emit('assign', this.plan);
    },
    onChoosePlan() {
      Tracking.planSelected('myPlan', this.plan.segmentSlug);
      if (this.plan.callConsultant) {
        this.callConsultant();
      } else {
        this.$emit('assign', this.plan);
      }
    },
    callConsultant() {
      return this.openChatSales();
    },
    openChatSales() {
      analyticsService.track({
        event: 'opened chat sales',
        props: {},
      });

      ZendeskService.addTags(['sales']);
      ZendeskService.openChatOnSales();
    },
  },
};
</script>
<style lang="scss" scoped>
.hs-next-plan__recommended-badge {
  border: 1px solid $cyan;
  border-radius: 50px;
}
.puppet-image {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.hs-next-plan-banner__img {
  max-width: 100px;
}
</style>
