<template>
  <div class="w-100 d-flex flex-column px-4 pb-3 pt-4 justify-content-between">
    <div class="w-100 pb-4">
      <h5>
        {{ $t('my-account.views.plan.actual-plan-panel.subtitle') }}
      </h5>
    </div>
    <FeaturesListing
      :members-free="membersFeaturesFree"
      :members-premium="membersFeaturesPremium"
      :funnels-free="funnelsFeaturesFree"
      :funnels-premium="funnelsFeaturesPremium"
    />
  </div>
</template>

<script>
import FeaturesListing from './FeaturesListing';
export default {
  components: { FeaturesListing },
  computed: {
    funnelsFeaturesFree() {
      return new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-free-features.${index}`),
      }));
    },
    membersFeaturesFree() {
      return new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.members-free-features.${index}`),
      }));
    },
    funnelsFeaturesPremium() {
      return new Array(6).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-pro-features.${index}`),
      }));
    },
    membersFeaturesPremium() {
      return new Array(4).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.members-pro-features.${index}`),
      }));
    },
  },
};
</script>
