<template>
  <div>
    <div class="d-none d-lg-block">
      <b-modal
        id="PlanDetailsModal"
        :visible="visible"
        :static="true"
        size="lg"
        centered
        hide-footer
        header-class="hs-plan-details-modal__header border-0 hs-bg-grey-10 px-3 px-lg-5"
        body-class="pt-0 px-3 px-lg-5"
        @hidden="onClose"
      >
        <template #modal-title>
          <div class="d-flex flex-column">
            <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <h2 class="font-weight-bold mr-2">
                {{ $t('my-account.views.plan.title') }}
                <span>{{ plan.title }}</span>
              </h2>
              <div v-if="plan.recommended && !isActual">
                <span
                  class="d-none d-lg-block hs-next-plan__recommended-badge badge-primary px-2 py-1 text-white font-weight-bold"
                >
                  {{ $t('my-account.views.plan.recommended') }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <PlanDetailsModalContent
          :plan="plan"
          :show-plan-listing-button="showPlanListingButton"
          :show-plan-pick-button="!isActual"
          @close="onClose"
          @pick="() => $emit('pick', plan)"
        />
      </b-modal>
    </div>
    <div
      class="hs-plan-details-modal__mobile-wrapper d-block d-lg-none position-fixed w-100 h-100 bg-white"
      v-if="visible"
    >
      <div class="hs-bg-grey-10 p-4 d-flex justify-content-between align-items-center">
        <h4 class="font-weight-bold">
          {{ $t('my-account.views.plan.title') }}
          <span>{{ plan.title }}</span>
        </h4>
        <span class="pointer hs-text-36 hs-lh-0" @click="onClose">×</span>
      </div>
      <div class="px-3 px-lg-5">
        <PlanDetailsModalContent
          :plan="plan"
          :show-plan-listing-button="showPlanListingButton"
          :show-plan-pick-button="!isActual"
          @close="onClose"
          @pick="() => $emit('pick', plan)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanDetailsModalContent from './PlanDetailsModalContent';

export default {
  components: { PlanDetailsModalContent },
  props: {
    plan: Object,
    isActual: Boolean,
    visible: {
      type: Boolean,
      default: false,
    },
    showPlanListingButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.hs-plan-details-modal__header {
  .close {
    font-size: 35px;
  }
}
.hs-next-plan__recommended-badge {
  border: 1px solid $cyan;
  border-radius: 50px;
}
.hs-plan-details-modal__mobile-wrapper {
  top: 0px;
  left: 0px;
  z-index: 2;
}
</style>
