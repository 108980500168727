<template>
  <div class="w-100" id="AssignPanel">
    <div v-if="!fullScreen" class="hs-assign-panel__overlay"></div>
    <div :class="fullScreen ? 'hs-assign-panel--fullscreen w-100 position-fixed left-0 top-0' : ''">
      <div
        class="hs-assign-panel__container w-100 d-flex flex-column"
        :class="fullScreen ? 'h-100' : 'overflow-hidden'"
      >
        <PaymentFailPanel v-if="error" :error="error" @reset="error = ''" @cancel="onAbort" />
        <PaymentAbortPanel
          data-id="aborted-panel"
          v-else-if="aborted"
          :plan="plan"
          @back="aborted = false"
          @cancel="$emit('close')"
        />
        <PaymentLoadingPanel data-id="loading-panel" v-else-if="loading" @close="$emit('close')" />
        <SignatureCompletePanel v-else-if="completePanelVisible" :plan="plan" @close="$emit('close')" />
        <DataConfirmationPanel
          v-else-if="dataConfirmationPanelVisible"
          @cancel="$emit('close')"
          @next="onDataConfirmed"
          :number="recipeData.number"
          :cep="recipeData.cep"
          :phone="recipeData.phone"
        />
        <CreditCardPanel
          data-id="credit-card-panel"
          v-else-if="!paymentSuccess"
          :plan="plan"
          @pay="onPay"
          @close="$emit('close')"
          @abort="onAbort"
        />
        <PaymentSuccessPanel v-else :plan="plan" @close="$emit('close')" @next="goToDataConfirmation" />
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardPanel from './CreditCardPanel';
import PaymentSuccessPanel from './PaymentSuccessPanel';
import planService from '@/services/heimdall/newPlans';
import PaymentFailPanel from './PaymentFailPanel';
import PaymentLoadingPanel from './PaymentLoadingPanel';
import PaymentAbortPanel from './PaymentAbortPanel';
import DataConfirmationPanel from './DataConfirmationPanel.vue';
import SignatureCompletePanel from './SignatureCompletePanel.vue';

import { mapActions, mapState } from 'vuex';
import { analyticsService } from '@/services';

export default {
  props: { fullScreen: Boolean, plan: Object },
  components: {
    CreditCardPanel,
    PaymentSuccessPanel,
    PaymentFailPanel,
    PaymentLoadingPanel,
    PaymentAbortPanel,
    DataConfirmationPanel,
    SignatureCompletePanel,
  },
  data() {
    return {
      paymentSuccess: false,
      loading: false,
      canceling: false,
      aborted: false,
      dataConfirmationPanelVisible: false,
      completePanelVisible: false,
      error: '',
      recipeData: {
        cep: '',
        number: '',
        phone: '',
      },
    };
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    onDataConfirmed() {
      this.dataConfirmationPanelVisible = false;
      this.completePanelVisible = true;
    },
    goToDataConfirmation(data) {
      this.recipeData = data;
      this.paymentSuccess = false;
      this.dataConfirmationPanelVisible = true;
    },
    async onPay({ paymentMethod, customer }) {
      this.loading = true;
      try {
        const response = await planService.changePlan({
          customer: {
            cpf: customer.cpf,
          },
          payment_params: {
            brand: paymentMethod.brand,
            type: 'scheme',
            holder_name: paymentMethod.holderName,
            encrypted_card_number: paymentMethod.encryptedCardNumber,
            encrypted_expiry_month: paymentMethod.encryptedExpiryMonth,
            encrypted_expiry_year: paymentMethod.encryptedExpiryYear,
            encrypted_security_code: paymentMethod.encryptedSecurityCode,
          },
          order: {
            plan_slug: this.plan.slug,
            origin: 'Core/Edools',
            months_duration: 1,
          },
        });

        if (response.data.data.order.status !== 'active') {
          this.onError('missing information');
          return;
        }

        analyticsService.track({
          event: 'Upgrade to Paid',
          props: {
            plan: this.plan.segmentSlug,
            school_id: this.selectedSchool.id,
          },
        });

        const school = this.selectedSchool;
        school.extra_settings.show_top_bar = 'false';

        await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });

        analyticsService.identify(undefined, {
          plan: this.plan.segmentSlug,
        });

        this.paymentSuccess = true;
      } catch (e) {
        this.onError(e.message);
      } finally {
        this.loading = false;
      }
    },
    onError(reason) {
      analyticsService.track({
        event: 'Upgrade Fail',
        props: {
          reason,
          school_id: this.selectedSchool.id,
        },
      });
      this.error = this.$t('my-account.views.plan.assign-panel.error');
    },
    onAbort() {
      this.error = '';
      this.aborted = true;
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
};
</script>

<style lang="scss">
.hs-assign-panel--fullscreen {
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 99;
  height: 100vh;
}
.hs-assign-panel__overlay {
  pointer-events: unset;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.hs-assign-panel__container {
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  z-index: 3;
}
.hs-assign-panel__header {
  background: $light-blue;
}
</style>
