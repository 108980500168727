<template>
  <div id="CreditCardPanel" class="d-flex flex-column h-100">
    <div class="hs-assign-panel__header p-4 d-flex justify-content-between align-items-center">
      <h4 class="font-weight-bold">{{ $t('my-account.views.plan.signature-panel.title') }}</h4>
      <span class="text-primary pointer hs-text-36 hs-lh-0" @click="onClose">×</span>
    </div>
    <div class="d-flex flex-column justify-content-between bg-white p-4 h-100 overflow-y-auto">
      <div>
        <div class="d-flex">
          <div class="col-1 col-lg-1 pl-0 mr-2 position-relative">
            <Step text="1" :completed="true" size="small" />
            <div class="position-absolute hs-assign-panel__progress badge-primary"></div>
          </div>
          <div class="w-100 d-flex flex-column">
            <div class="d-flex justify-content-between mt-2">
              {{ $t('my-account.views.plan.signature-panel.plan') }} {{ plan.title }}
              <div class="col-4 d-flex justify-content-end position-relative px-0">
                <span class="hs-credit-card-panel__month-span position-absolute">
                  {{ $t('my-account.views.plan.month') }} ({{ plan.currency }})
                </span>
                <span>{{ plan.price.toFixed(2) }}</span>
              </div>
            </div>
            <hr class="w-100" />
          </div>
        </div>
        <div class="d-flex">
          <div class="col-1 col-lg-1 pl-0 mr-2 position-relative">
            <Step text="2" :activated="!isCardFilled" :completed="isCardFilled" size="small" />
            <div
              class="position-absolute hs-assign-panel__progress"
              :class="isCardFilled ? 'badge-primary' : 'hs-bg-grey-20'"
            ></div>
          </div>
          <div class="hs-credit-card-panel__form-wrapper d-flex flex-column justify-content-between w-100">
            <div class="d-flex flex-column">
              <h5 class="font-weight-bold mb-2 ">{{ $t('my-account.views.plan.credit-card-panel.payment-data') }}</h5>
              <CreditCardForm @change="onCardChange" />
            </div>
            <hr class="w-100" />
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="col-1 col-lg-1 pl-0 mr-2">
            <Step text="3" :activated="isCardFilled && !isCPFOk" :completed="isCPFOk" size="small" />
          </div>
          <div class="w-100">
            <hs-group
              :label="$t('my-account.views.plan.credit-card-panel.cpf')"
              label-for="cpf"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
              data-testid="cpf-input"
            >
              <the-mask
                :disabled="!isCardFilled"
                id="cpf"
                placeholder="___.___.___-__"
                class="form-control hs-credit-card-panel__input"
                :mask="'###.###.###-##'"
                :masked="true"
                v-model="cpf"
              />
            </hs-group>
          </div>
        </div>
        <div class="hs-text-14 hs-text-grey-30">
          {{ $t('my-account.views.plan.credit-card-panel.info') }}
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <hs-button
          :variant="isButtonDisabled ? 'secondary' : 'primary'"
          class="w-100"
          data-testid="submit-btn"
          @click="processPayment"
          :disabled="isButtonDisabled"
        >
          {{ $t('my-account.views.plan.credit-card-panel.submit') }}
        </hs-button>
      </div>
      <div class="d-flex mt-3 justify-content-around px-4">
        <img :src="require('@/assets/images/secure-purchase.png')" width="100" />
        <img :src="require('@/assets/images/protected-data.png')" width="100" />
      </div>
    </div>
    <TermModal id="assign-plan__account-terms" kind="account_terms" :show-error-alert="false" />
    <TermModal id="assign-plan__privacy_policy" kind="privacy_policy" :show-error-alert="false" />
  </div>
</template>

<script>
import TermModal from '@/components/TermModal';
import Step from '@/components/Stepper/Step';
import CreditCardForm from './CreditCardForm';
import { analyticsService } from '@/services';
import { TheMask } from 'vue-the-mask';

export default {
  props: { loading: Boolean, plan: Object },
  components: { CreditCardForm, TermModal, TheMask, Step },
  data() {
    return {
      paymentMethod: {},
      cpf: '',
      cardAddedEventSent: false,
    };
  },
  methods: {
    onCardChange(cardData) {
      this.paymentMethod = cardData;
    },
    openTermModal() {
      this.$bvModal.show('assign-plan__account-terms');
    },
    openPrivacityModal() {
      this.$bvModal.show('assign-plan__privacy_policy');
    },
    processPayment() {
      this.$emit('pay', {
        paymentMethod: this.paymentMethod,
        customer: {
          cpf: this.cpf,
        },
      });
    },
    onClose() {
      if (this.isCardFilled) {
        this.$emit('abort');
      } else {
        this.$emit('close');
      }
    },
  },
  watch: {
    isCardFilled(val) {
      if (val && !this.cardAddedEventSent) {
        analyticsService.track({
          event: 'Credit Card Added',
          props: {},
        });
        this.cardAddedEventSent = true;
      }
    },
  },
  computed: {
    isCardFilled() {
      return (
        !!this.paymentMethod.encryptedCardNumber &&
        !!this.paymentMethod.encryptedExpiryMonth &&
        !!this.paymentMethod.encryptedExpiryYear &&
        !!this.paymentMethod.encryptedSecurityCode &&
        !!this.paymentMethod.holderName
      );
    },
    isButtonDisabled() {
      return !this.isCardFilled || !this.isCPFOk;
    },
    isCPFOk() {
      return !!(this.cpf && this.cpf.length === 14);
    },
  },
};
</script>

<style lang="scss">
.hs-assign-panel__progress {
  width: 5px;
  height: 100%;
  left: 8px;
}
.hs-credit-card-panel__month-span {
  top: -20px;
  right: 0px;
  font-size: 0.8rem;
}
.hs-credit-card-panel__form-wrapper {
  height: 280px;
}
.hs-credit-card-panel__input-label {
  color: $grey-25 !important;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0px;
}
.hs-credit-card-panel__input::placeholder {
  color: rgb(185, 196, 201);
}
</style>
