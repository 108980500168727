<template>
  <div>
    <b-tabs
      nav-class="hs-plan-mobile-tabs bg-white px-sm-5 text-dark"
      active-nav-item-class="hs-plan-mobile-tab--active font-weight-bold"
      nav-wrapper-class="hs-mobile-tabs__scroll-container d-flex overflow-x-auto"
      :fill="true"
      :active-tab-class="`px-0 ${tabIndex === 2 ? 'hs-plan-mobile-tabs__tab-wrapper' : ''}`"
    >
      <b-tab
        :active="tabIndex === 0"
        @click="() => onTabClick('MyAccountPlan', 0)"
        :title="$t('my-account.views.plan.mobile-tabs.plan-details')"
        title-link-class="col-12 py-3 border-0 text-dark"
      >
        <div class="d-flex flex-column m-2 m-lg-5 pr-lg-5 p-2 px-lg-0">
          <div class="col-12 col-lg-7 px-0 mr-5">
            <ActualPlanPanel :plan="plan" />
            <div class="d-flex flex-column" v-if="nextPlan">
              <h4 class="mt-4">
                {{ $t('my-account.views.plan.plan-offer') }}
              </h4>
              <hr class="w-100" />
              <NextPlanBanner :plan="nextPlan" @assign="onPlanAssign" />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab
        :active="tabIndex === 1"
        @click="() => onTabClick('MyAccountPlan', 1)"
        :title="$t('my-account.views.plan.mobile-tabs.billing')"
        title-link-class="py-3 border-0 text-dark position-relative"
        class="p-3"
      >
        <SignaturePanel :plan="plan" v-if="!assignPanelVisible" />
      </b-tab>
      <b-tab
        :active="tabIndex === 2"
        @click="() => onTabClick('MyAccountPlans', 2)"
        :title="$t('my-account.views.plan.mobile-tabs.plan-listing')"
        title-link-class="py-3 border-0 text-dark position-relative"
        class="p-3"
      >
        <PlanListing @assign="onPlanAssign" :plan="plan" />
      </b-tab>
    </b-tabs>
    <AssignPanel
      v-if="assignPanelVisible"
      :full-screen="true"
      :plan="planToAssign"
      @close="assignPanelVisible = false"
    />
  </div>
</template>

<script>
import planService from '@/services/heimdall/newPlans';
import ActualPlanPanel from './ActualPlanPanel/index';
import NextPlanBanner from './NextPlanBanner';
import SignaturePanel from './SignaturePanel/index';
import AssignPanel from './AssignPanel/index';
import PlanListing from '../../PlanListing/components/PlanListing';
import { mapMutations, mapState } from 'vuex';

export default {
  components: { ActualPlanPanel, NextPlanBanner, SignaturePanel, AssignPanel, PlanListing },
  props: {
    plan: Object,
  },
  data() {
    return {
      planToAssign: null,
      assignPanelVisible: false,
      tabIndex: this.selectedTab,
    };
  },
  mounted() {
    this.tabIndex = this.selectedTab;
    setTimeout(() => {
      this.refreshScrollPosition();
    }, 50);
  },
  methods: {
    ...mapMutations('plan', ['setMyAccountTab']),
    onPlanAssign(plan) {
      this.planToAssign = plan;
      this.assignPanelVisible = true;
    },
    onTabClick(routeName, tabIndex) {
      this.$router.push({ name: routeName });
      this.setMyAccountTab(tabIndex);
    },
    refreshScrollPosition() {
      const scrollElement = document.querySelector('.hs-mobile-tabs__scroll-container');
      if (scrollElement) {
        const leftGap = (window.innerWidth - 200) / 2;
        scrollElement.scrollTo(this.tabIndex * 200 - leftGap, 0);
      }
    },
  },
  computed: {
    ...mapState('plan', ['selectedTab']),
    nextPlan() {
      return planService.getNextPlan(this.plan);
    },
  },
  watch: {
    selectedTab(val) {
      this.tabIndex = val;
      this.refreshScrollPosition();
    },
  },
};
</script>

<style lang="scss">
.hs-plan-mobile-tabs {
  border-bottom-width: 3px;
  flex-wrap: nowrap;
  .nav-item {
    width: 200px;
  }
}
@media only screen and (min-width: $screen-sm) {
  .hs-plan-mobile-tabs {
    width: 100%;
  }
}
.hs-plan-mobile-tab--active {
  border-bottom: 3px solid var(--cherry) !important;
  margin-bottom: -2px;
}
.hs-plan-mobile-tabs__tab-wrapper {
  padding: 0px !important;
}
</style>
