<template>
  <div>
    <div class="d-none d-lg-block">
      <b-modal
        id="PlanDetailsModal"
        :visible="visible"
        :static="true"
        size="xl"
        centered
        hide-footer
        header-class="hs-invoice-modal border-0 hs-bg-grey-10 px-3 px-lg-5"
        body-class="p-0"
        @hidden="$emit('close')"
      >
        <template #modal-title>
          <div class="d-flex flex-column">
            <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
              <h2 class="font-weight-bold mr-2">
                {{ $t('my-account.views.plan-invoices.invoice') }} {{ invoiceMonth }}
              </h2>
            </div>
          </div>
        </template>
        <InvoiceModalContent :invoice="invoice" />
      </b-modal>
    </div>
    <div class="hs-invoice-modal__mobile-wrapper d-block d-lg-none position-fixed w-100 h-100 bg-white" v-if="visible">
      <div class="hs-bg-grey-10 p-4 d-flex justify-content-between align-items-center">
        <h4 class="font-weight-bold">
          <span>{{ $t('my-account.views.plan-invoices.invoice') }} {{ invoiceMonth }}</span>
        </h4>
        <span class="pointer hs-text-36 hs-lh-0" @click="$emit('close')">×</span>
      </div>
      <div class="px-3 px-lg-5">
        <InvoiceModalContent :invoice="invoice" />
      </div>
    </div>
  </div>
</template>

<script>
import datesHelper from '@/shared/helpers/dates';
import InvoiceModalContent from './InvoiceModalContent.vue';

export default {
  components: { InvoiceModalContent },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    invoice: Object,
  },
  computed: {
    invoiceMonth() {
      return datesHelper.format({ date: this.invoice.date, format: 'MMMM' });
    },
  },
};
</script>

<style lang="scss">
.hs-invoice-modal {
  .close {
    font-size: 35px;
  }
}
.hs-invoice-modal__mobile-wrapper {
  top: 0px;
  left: 0px;
  z-index: 2;
}
</style>
