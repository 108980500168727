<template>
  <div id="PaymentFailPanel" class="d-flex flex-column h-100 bg-white">
    <div class="hs-assign-panel__header p-4 d-flex justify-content-between align-items-center">
      <h4 class="font-weight-bold">{{ $t('my-account.views.plan.signature-panel.title') }}</h4>
      <span class="text-primary pointer hs-text-36 hs-lh-0" @click="$emit('cancel')">×</span>
    </div>
    <div class="d-flex flex-column justify-content-between h-100" style="min-height:525px">
      <div class="d-flex flex-column justify-content-center align-items-center mt-5 px-5">
        <img :src="require('@/assets/images/robot-fail.svg')" width="150" />
        <span class="font-weight-bold mt-3 text-center">{{
          $t('my-account.views.plan.payment-fail-panel.content')
        }}</span>
      </div>
      <div class="p-4">
        <hs-button variant="primary" class="w-100" @click="$emit('reset')">
          {{ $t('my-account.views.plan.payment-fail-panel.return') }}
        </hs-button>
        <hs-button variant="outline-primary" class="w-100 mt-3" @click="$emit('cancel')">
          {{ $t('my-account.views.plan.payment-fail-panel.cancel') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
