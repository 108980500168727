<template>
  <div class="d-flex flex-column justify-content-between overflow-y-auto pb-3 pb-lg-0">
    <div>
      <div class="mb-3 mt-3">
        <span>{{ $t('my-account.views.plan.change-cc-modal.actual-payment-method') }}</span>
        <div class="hs-box-shadow p-3 d-flex justify-content-between mt-2">
          <span>{{ $t('my-account.views.plan.change-cc-modal.credit-charge') }}</span>
          <div class="d-flex align-items-center">
            <span class="mr-2">**** **** **** {{ paymentInfo.creditCard.last4Digits }}</span>
            <img
              :src="
                `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${
                  paymentInfo.creditCard.brand
                }.svg`
              "
              width="30"
            />
          </div>
        </div>
      </div>

      <div class="mb-3 mt-3">
        <span>{{ $t('my-account.views.plan.change-cc-modal.new-card') }}</span>
        <div class="hs-change-cc-modal-content__form-wrapper hs-box-shadow p-3 d-flex justify-content-between mt-2">
          <CreditCardForm @change="onCardChange" />
        </div>
      </div>
      <p class="hs-text-14">
        {{ $t('my-account.views.plan.change-cc-modal.info-message') }}
      </p>
    </div>

    <div class="d-flex justify-content-end">
      <hs-button
        class="w-100 w-lg-auto"
        :variant="isCardFilled ? 'primary' : 'secondary'"
        :disabled="!isCardFilled"
        @click="onUpdateCard"
      >
        {{ $t('my-account.views.plan.change-cc-modal.update-data') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import { analyticsService } from '@/services';
import CreditCardForm from '../AssignPanel/CreditCardForm';

export default {
  components: { CreditCardForm },
  props: {
    paymentInfo: Object,
  },
  data() {
    return {
      paymentMethod: null,
    };
  },
  methods: {
    onCardChange(cardData) {
      this.$nextTick(() => {
        this.paymentMethod = cardData;
      });
    },
    onUpdateCard() {
      // TODO send the update card request
      analyticsService.track({
        event: 'Credit Card Updated',
        props: {},
      });
    },
  },
  computed: {
    isCardFilled() {
      return (
        this.paymentMethod &&
        this.paymentMethod.encryptedCardNumber &&
        this.paymentMethod.encryptedExpiryMonth &&
        this.paymentMethod.encryptedExpiryYear &&
        this.paymentMethod.encryptedSecurityCode &&
        this.paymentMethod.holderName
      );
    },
  },
};
</script>

<style>
.hs-change-cc-modal-content__form-wrapper {
  height: 254px;
}
</style>
