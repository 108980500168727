<template>
  <section class="tlw-flex tlw-items-center tlw-p-5 tlw-container">
    <main class="tlw-w-full lg:tlw-w-1/2">
      <h1 class="tlw-text-red-500 tlw-uppercase tlw-text-lg tlw-font-black">
        {{ $t('my-account.views.plan.plan-hero-header.label') }}
      </h1>
      <h2 class="tlw-mt-5 tlw-text-6xl tlw-font-bold">
        {{ $t('my-account.views.plan.plan-hero-header.slogan') }}
      </h2>
      <p class="tlw-mt-5 tlw-text-xl">
        {{ $t('my-account.views.plan.plan-hero-header.description') }}
      </p>
      <button
        data-test="see-plans"
        class="tlw-py-3 tlw-px-5 tlw-rounded tlw-mt-5 tlw-transition-all tlw-font-extrabold tlw-ease-in-out tlw-bg-purple-500 tlw-text-white hover:tlw-bg-purple-600"
        @click="onClick"
      >
        {{ $t('my-account.views.plan.plan-hero-header.button-label') }}
      </button>
    </main>
    <aside class="tlw-hidden lg:tlw-block tlw-w-1/2 tlw-text-center">
      <img
        class="tlw-w-1/2 tlw-mx-auto"
        src="@/assets/images/funnel/sell-something.svg"
        :alt="$t('my-account.views.plan.plan-hero-header.label')"
      />
    </aside>
  </section>
</template>

<script>
export default {
  name: 'PlansHeroHeader',
  methods: {
    onClick() {
      this.$emit('clicked');
    },
  },
};
</script>
