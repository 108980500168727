<template>
  <section class="d-flex position-relative flex-fill flex-column">
    <hsPageHeader
      class="d-none d-lg-block"
      :title="$t('my-account.views.plan.plan-listing.header')"
      :back-text="$t('my-account.views.plan.back')"
      back-route="MyAccountPlan"
    />
    <MobileTabs data-test="mobile-tab" class="d-block d-lg-none" :plan="actualPlan" v-if="actualPlan" />
    <div v-if="actualPlan" class="m-lg-3 px-2">
      <PlansHeroHeader data-test="hero-header" @clicked="scrollToPlans" />
      <section class="d-none d-lg-flex flex-column flex-lg-row m-2 tlw-relative tlw-mt-5" ref="plansList">
        <PlanListing data-test="plan-listing" @assign="onAssign" :plan="actualPlan" />
        <AssignPanel
          data-test="assign-panel"
          v-if="assignPanelVisible"
          :plan="planToAssign"
          @close="assignPanelVisible = false"
        />
      </section>
      <PlansMoreInfo data-test="more-info" />
      <PlansFrequentAsks data-test="faq" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import MobileTabs from '../Plan/components/MobileTabs';
import PlansFrequentAsks from './components/PlansFrequentAsks';
import PlansHeroHeader from './components/PlansHeroHeader';
import PlansMoreInfo from './components/PlansMoreInfo';
import PlanListing from './components/PlanListing';
import AssignPanel from '../Plan/components/AssignPanel';
import TrackingHelper from '@/shared/helpers/tracking';

export default {
  components: {
    hsPageHeader,
    MobileTabs,
    PlansFrequentAsks,
    PlansHeroHeader,
    PlansMoreInfo,
    PlanListing,
    AssignPanel,
  },
  data() {
    return {
      assignPanelVisible: false,
      planToAssign: null,
    };
  },
  async mounted() {
    this.setMyAccountTab(2);
    if (!this.$store.state.plan.actualPlan) {
      await this.refreshActualPlan(this.selectedSchool);
    }

    const analitcsOfferBaseProps = {
      school_id: this.selectedSchool.id,
    };

    const location = this.$route?.query?.location || 'plan_list';
    TrackingHelper.trackPlanListViewed(location, analitcsOfferBaseProps.school_id);
  },
  methods: {
    ...mapMutations('plan', ['setMyAccountTab']),
    ...mapActions('plan', ['refreshActualPlan']),
    onAssign(plan) {
      this.planToAssign = plan;
      this.assignPanelVisible = true;
    },
    scrollToPlans() {
      this.$refs['plansList'].scrollIntoView({ behavior: 'smooth' });
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('plan', ['actualPlan']),
  },
};
</script>
