<template>
  <section class="d-flex position-relative flex-fill flex-column">
    <hsPageHeader
      class="d-none d-lg-block"
      :title="$t('my-account.views.plan.downgrade-plan.title')"
      :back-text="$t('my-account.views.plan.back')"
      back-route="MyAccountPlans"
    />
    <div class="d-flex flex-column flex-lg-row m-lg-2 m-lg-5 pr-lg-5 h-100 h-lg-auto" v-if="actualPlan">
      <div class="d-flex d-lg-none bg-white justify-content-between align-items-center font-weight-bold py-3 px-4 mt-1">
        <h3>{{ $t('my-account.views.plan.downgrade-plan.title') }}</h3>
        <span class="pointer hs-text-36 hs-lh-0" @click="onClose">×</span>
      </div>
      <div class="hs-plan-downgrade col-lg-7 p-4 mr-5 rounded h-100 d-flex flex-column">
        <span>{{ $t('my-account.views.plan.downgrade-plan.subtitle') }} {{ destinationPlan.title }}</span>
        <div class="d-none d-lg-flex flex-column mt-4">
          <QuestionWrapper :question="question" :actualStep="1" :box-mode="false" @selected="onOptionSelected" />
          <div class="d-flex justify-content-end mt-3">
            <hs-button
              variant="primary"
              @click="onDowngradePlan"
              :disabled="(!optionSelected && !optionSelected) || replyMoreQuestionIsBlank()"
            >
              {{ $t('my-account.views.plan.downgrade-plan.change-plan') }}
            </hs-button>
          </div>
        </div>
        <div class="d-flex d-lg-none flex-column mt-4 h-100">
          <div v-if="step === 0" class="d-flex flex-column justify-content-between h-100">
            <QuestionWrapper
              data-id="question"
              :question="question"
              :actualStep="1"
              :box-mode="false"
              @selected="onOptionSelected"
            />
            <hs-button
              variant="primary"
              @click="step = 1"
              :disabled="(!optionSelected && !optionSelected) || replyMoreQuestionIsBlank()"
            >
              {{ $t('my-account.views.plan.downgrade-plan.continue') }}
            </hs-button>
          </div>
          <div v-else class="d-flex flex-column justify-content-between h-100">
            <div class="d-flex flex-column">
              <SignatureChangePanel :actual="actualPlan" :destination="destinationPlan" />
              <div>
                <a role="button" class="pointer btn py-3 pr-4 pl-0 text-primary" @click="step = 0">
                  <hs-icon icon="arrow-left" class="mr-2"></hs-icon>
                  {{ $t('sign_up.questionary.back') }}
                </a>
              </div>
            </div>
            <hs-button
              variant="primary"
              @click="onDowngradePlan"
              :disabled="(!optionSelected && !optionSelected) || replyMoreQuestionIsBlank()"
            >
              {{ $t('my-account.views.plan.downgrade-plan.change-plan') }}
            </hs-button>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 px-0 mt-2 mt-lg-0">
        <SignatureChangePanel :actual="actualPlan" :destination="destinationPlan" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import planService from '@/services/heimdall/newPlans';
import hsPageHeader from '@/components/_structures/PageHeader';
import SignatureChangePanel from './SignatureChangePanel';
import QuestionWrapper from '@/auth/views/SignUp/components/StepQuestionary/QuestionWrapper';
import toastHelper from '@/shared/helpers/toast';
import { analyticsService } from '@/services';
import Confirm from '@/shared/mixins/Confirm';
import _ from 'lodash';

export default {
  components: { hsPageHeader, SignatureChangePanel, QuestionWrapper },
  mixins: [Confirm],
  data() {
    return {
      order: {},
      optionSelected: null,
      step: 0,
      question: {
        value: 'sellCourse',
        selectedOption: '',
        replyMoreQuestions: {
          want_to_back: null,
          what_missed: null,
          what_choose_another_platform: null,
          what_errors_impacted_project: null,
          what_biggest_difficulty: null,
          have_interest_mkt: null,
          reason_other: null,
        },
        text: this.$t('my-account.views.plan.downgrade-plan.question.title'),
        options: [
          {
            id: 1,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.1'),
            value: 'reason_pause_project',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.1'),
                type: 'Boolean',
                name: 'want_to_back',
              },
            ],
          },
          {
            id: 2,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.2'),
            value: 'reason_does_not_meet',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.2'),
                type: 'String',
                name: 'what_missed',
              },
            ],
          },
          {
            id: 3,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.3'),
            value: 'reason_going_to_others_platform',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.3'),
                type: 'String',
                name: 'what_choose_another_platform',
              },
            ],
          },
          {
            id: 4,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.4'),
            value: 'reason_bugs_platform',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.4'),
                type: 'String',
                name: 'what_errors_impacted_project',
              },
            ],
          },
          {
            id: 5,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.5'),
            value: 'reason_usability_hard',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.5'),
                type: 'String',
                name: 'what_biggest_difficulty',
              },
            ],
          },
          {
            id: 6,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.6'),
            value: 'reason_wasnt_successful_sales',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.6'),
                type: 'Boolean',
                name: 'have_interest_mkt',
              },
            ],
          },
          {
            id: 7,
            type: 'text',
            title: this.$t('my-account.views.plan.downgrade-plan.question.options.7'),
            value: 'reason_other',
            selected: false,
            moreQuestion: [
              {
                question: this.$t('my-account.views.plan.downgrade-plan.question.options.moreQuestion.7'),
                type: 'String',
                name: 'reason_other',
              },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    if (!this.actualPlan) {
      await this.refreshActualPlan(this.selectedSchool);
    }
    this.getPlanOrder();
  },
  methods: {
    ...mapActions('plan', ['refreshActualPlan']),
    onOptionSelected({ option }) {
      this.question.replyMoreQuestions = {
        want_to_back: null,
        what_missed: null,
        what_choose_another_platform: null,
        what_errors_impacted_project: null,
        what_biggest_difficulty: null,
        have_interest_mkt: null,
        reason_other: null,
      };
      this.optionSelected = option;
      this.question.options.forEach(opt => {
        opt.selected = opt.id === option.id;
      });
    },
    onClose() {
      this.$router.push({ name: 'MyAccountPlans' });
    },
    trackChangePlan() {
      let reason = this.optionSelected.value;
      let filteredValue = this.replyMoreQuestionsValue();
      let questionTheMoreQuestion = this.getQuestionMoreQuestion(Object.keys(filteredValue)[0]);

      if (reason.type === 'input') {
        reason = `${this.optionSelected.value} - ${this.optionSelected.inputValue}`;
      }
      if (this.replyMoreQuestionsValue().length != 0) {
        var reason_comment = `${questionTheMoreQuestion}: ${filteredValue[Object.keys(filteredValue)]}`;
      }
      analyticsService.track({
        event: 'Downgrade Questionary Filled',
        props: {
          reason,
          reason_comment,
          school_id: this.selectedSchool.id,
        },
      });
      analyticsService.identify(undefined, {
        plan: this.destinationPlan.slug,
      });
    },
    replyMoreQuestionsValue() {
      return _.omitBy(this.question.replyMoreQuestions, _.isNil);
    },
    replyMoreQuestionIsBlank() {
      return _.isEmpty(this.replyMoreQuestionsValue());
    },
    async onDowngradePlan() {
      const confirmBoxOptions = {
        okTitle: 'Cancelar plano',
        cancelTitle: 'Sair',
        contentTitle: 'Cancelar assinatura',
        contentDescription: 'Tem certeza que deseja cancelar sua assinatura?',
      };

      const confirmResponse = await this.showConfirmBox(confirmBoxOptions);
      let filteredValue = this.replyMoreQuestionsValue();
      if (!confirmResponse) return;
      try {
        const questionTheMoreQuestion = this.getQuestionMoreQuestion(Object.keys(filteredValue)[0]);
        const data = {
          cancel_params: {
            cancel_motivation: this.optionSelected.value,
            cancel_feedback: `${questionTheMoreQuestion}: ${filteredValue[Object.keys(filteredValue)]}`,
            cancel_description: '',
          },
        };
        await planService.downgradePlan({ id: this.order.id, data });
        this.trackChangePlan();
        await this.refreshActualPlan(this.selectedSchool);
        this.$router.push({ name: 'MyAccountPlan' });
      } catch (error) {
        toastHelper.dangerMessage(this.$t('my-account.views.plan.downgrade-plan.error'));
      }
    },
    async getPlanOrder() {
      const orders = await planService.getOrders();
      this.order = orders[0];
    },
    getQuestionMoreQuestion(name_question) {
      var question = '';
      this.question.options.forEach(i => {
        if (i.moreQuestion[0].name === name_question) {
          question = i.moreQuestion[0].question;
        }
      });
      return question;
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('plan', ['actualPlan']),
    destinationPlan() {
      if (this.actualPlan) {
        return planService.getPreviousPlan(this.actualPlan);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.hs-plan-downgrade {
  background: transparent;
}
@media screen and (min-width: $screen-lg) {
  .hs-plan-downgrade {
    background: white;
  }
}
.hs-plan-downgrade .custom-control-label {
  width: 100%;
  padding: 0.3rem;
  cursor: pointer;
}
.hs-plan-downgrade .custom-control-label::before,
.hs-plan-downgrade .custom-control-label::after {
  margin-top: 0.3rem;
}
</style>
