






























































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PageHeader from '@/components/_structures/PageHeader.vue';
import MButton from '@/shared/components/MButton.vue';
import HsSwitch from '@/components/hsSwitch.vue';
import ToastHelper from '@/shared/helpers/toast';
import Loading from '@/components/Loading.vue';
import pagesService from '@/services/pages';
import { SlugPages } from '@/libs/pages';

const JWTModule = namespace('jwt');
const SchoolModule = namespace('school');

@Component({
  components: {
    PageHeader,
    MButton,
    HsSwitch,
    Loading,
  },
})
export default class Vitrine extends Vue {
  @SchoolModule.State selectedSchool!: any;
  @SchoolModule.Getter getDomainWithHttp!: string;

  @JWTModule.State encoded!: string;
  @JWTModule.Action load!: any;
  @JWTModule.Getter isLoaded!: any;

  prefixLang: string = 'myspark.vitrine';
  tips: number = 0;
  page: any = {};
  isLoading: boolean = true;
  isUpdating: boolean = false;

  get mainDomain() {
    return this.selectedSchool.account_type === 'herospark'
      ? process.env.VUE_APP_HERO_DOMAIN
      : process.env.VUE_APP_DOMAIN_URL;
  }

  get pageDomain() {
    return `https://${this.selectedSchool.subdomain}.${this.mainDomain}`;
  }

  get editPageUrl() {
    return `${process.env.VUE_APP_PAGES_BASE_URL}/editor/${this.page.page_id}?token=${this.encoded}&returnUrl=${
      this.returnUrl
    }`;
  }

  get returnUrl() {
    const { origin, pathname } = window.location;
    return encodeURIComponent(`${origin}${pathname}`);
  }

  get pageUrl() {
    return `${this.getDomainWithHttp}/p/${SlugPages.VITRINE}`;
  }

  async created() {
    await this.loadJWTToken();
    await this.getPages();
  }

  async loadJWTToken() {
    if (!this.isLoaded) {
      await this.load();
    }
  }

  async getPages() {
    try {
      this.isLoading = true;
      const res = await pagesService.getPages();
      this.page = res.find(page => page.slug === 'home') || {};
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error.load-pages`));
    } finally {
      this.isLoading = false;
    }
  }

  async togglePublishVitrine() {
    try {
      this.isUpdating = true;

      if (this.page.page_id) {
        this.page = await pagesService.putPage(this.page.page_id, !this.page.published);
      } else {
        await this.createPage(true);
      }

      this.page.published
        ? ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.success.page-published`))
        : ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.success.page-unpublished`));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error.publish-page`));
    } finally {
      this.isUpdating = false;
    }
  }

  async viewOrEditPage(pageUrl: string) {
    if (this.page.page_id) {
      window.open(pageUrl, '_blank');
    } else {
      await this.createPage();
      const updatedPageUrl = pageUrl.search('editor') !== -1 ? this.editPageUrl : this.pageUrl;
      window.open(updatedPageUrl, '_blank');
    }
  }

  async createPage(published: boolean = false) {
    try {
      this.isUpdating = true;
      this.page = await pagesService.postPage(SlugPages.VITRINE, process.env.VUE_APP_PAGES_VITRINE_THEME_ID, published);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error.access-page`));
    } finally {
      this.isUpdating = false;
    }
  }
}
