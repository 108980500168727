<template>
  <div id="AddressPanel" class="d-flex flex-column h-100">
    <div class="hs-assign-panel__header p-4 d-flex justify-content-between align-items-center">
      <h4 class="font-weight-bold">
        {{ $t('my-account.views.plan.data-confirmation-panel.title') }}
      </h4>
    </div>
    <div class="d-flex flex-column justify-content-between h-100 bg-white p-4">
      <span class="mb-4">
        {{ $t('my-account.views.plan.data-confirmation-panel.subtitle') }}
      </span>
      <div class="d-flex flex-column justify-content-start h-100 overflow-y-auto">
        <div class="w-100">
          <hs-group
            :label="$t('my-account.views.plan.data-confirmation-panel.fullname')"
            label-for="fullName"
            label-class="hs-credit-card-panel__input-label"
            class="mb-2"
          >
            <hs-input
              id="fullName"
              v-model="form.fullName"
              :state="$v.form.fullName.$error ? false : null"
              @blur="$v.form.fullName.$touch()"
            />
          </hs-group>
          <hs-form-invalid-feedback :state="!$v.form.fullName.$error">
            <span class="font-size-xs" v-if="!$v.form.fullName.required">
              {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
            </span>
          </hs-form-invalid-feedback>
        </div>

        <div class="w-100">
          <hs-group
            :label="$t('my-account.views.plan.data-confirmation-panel.cpf')"
            label-for="cpf"
            label-class="hs-credit-card-panel__input-label"
            class="mb-2"
          >
            <the-mask
              id="cpf"
              placeholder="___.___.___-__"
              class="form-control hs-credit-card-panel__input"
              :mask="'###.###.###-##'"
              :masked="true"
              v-model="form.cpf"
            />
          </hs-group>
          <hs-form-invalid-feedback :state="!$v.form.cpf.$error">
            <span class="font-size-xs" v-if="!$v.form.cpf.required">
              {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
            </span>
          </hs-form-invalid-feedback>
        </div>

        <div class="d-flex">
          <div class="w-50">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.cep')"
              label-for="cep"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <the-mask
                id="cep"
                placeholder="_____-___"
                class="form-control hs-credit-card-panel__input"
                :mask="'#####-###'"
                :masked="true"
                v-model="form.cep"
              />
            </hs-group>
          </div>
          <div class="w-50 ml-3">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.number')"
              label-for="number"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input
                id="number"
                v-model="form.number"
                :state="$v.form.number.$error ? false : null"
                @blur="$v.form.number.$touch()"
              />
            </hs-group>
            <hs-form-invalid-feedback :state="!$v.form.number.$error">
              <span class="font-size-xs" v-if="!$v.form.number.required">
                {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
              </span>
            </hs-form-invalid-feedback>
          </div>
        </div>

        <div class="d-flex">
          <div class="w-50">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.country')"
              label-for="country"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input
                id="country"
                v-model="form.country"
                :state="$v.form.country.$error ? false : null"
                @blur="$v.form.country.$touch()"
              />
            </hs-group>
            <hs-form-invalid-feedback :state="!$v.form.country.$error">
              <span class="font-size-xs" v-if="!$v.form.country.required">
                {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
              </span>
            </hs-form-invalid-feedback>
          </div>
          <div class="w-50 ml-3">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.state')"
              label-for="state"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input
                id="state"
                v-model="form.state"
                :state="$v.form.state.$error ? false : null"
                @blur="$v.form.state.$touch()"
              />
            </hs-group>
            <hs-form-invalid-feedback :state="!$v.form.state.$error">
              <span class="font-size-xs" v-if="!$v.form.state.required">
                {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
              </span>
            </hs-form-invalid-feedback>
          </div>
        </div>

        <div class="d-flex flex-column">
          <hs-group
            :label="$t('my-account.views.plan.data-confirmation-panel.city')"
            label-for="city"
            label-class="hs-credit-card-panel__input-label"
            class="mb-2 w-100"
          >
            <hs-input
              id="city"
              v-model="form.city"
              :state="$v.form.city.$error ? false : null"
              @blur="$v.form.city.$touch()"
            />
          </hs-group>
          <hs-form-invalid-feedback :state="!$v.form.city.$error">
            <span class="font-size-xs" v-if="!$v.form.city.required">
              {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
            </span>
          </hs-form-invalid-feedback>
        </div>

        <div class="d-flex">
          <div class="col-8 px-0">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.street')"
              label-for="street"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input
                id="street"
                v-model="form.street"
                :state="$v.form.street.$error ? false : null"
                @blur="$v.form.street.$touch()"
              />
            </hs-group>
            <hs-form-invalid-feedback :state="!$v.form.street.$error">
              <span class="font-size-xs" v-if="!$v.form.street.required">
                {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
              </span>
            </hs-form-invalid-feedback>
          </div>
          <div class="col-4 px-0 pl-3">
            <hs-group
              :label="$t('my-account.views.plan.data-confirmation-panel.complement')"
              label-for="complement"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input id="complement" v-model="form.complement" />
            </hs-group>
          </div>
        </div>

        <div class="d-flex flex-column">
          <hs-group
            :label="$t('my-account.views.plan.data-confirmation-panel.neighborhood')"
            label-for="neighborhood"
            label-class="hs-credit-card-panel__input-label"
            class="w-100 mb-2"
          >
            <hs-input
              id="neighborhood"
              v-model="form.neighborhood"
              :state="$v.form.neighborhood.$error ? false : null"
              @blur="$v.form.neighborhood.$touch()"
            />
          </hs-group>
          <hs-form-invalid-feedback :state="!$v.form.neighborhood.$error">
            <span class="font-size-xs" v-if="!$v.form.neighborhood.required">
              {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
            </span>
          </hs-form-invalid-feedback>
        </div>

        <hs-group :label="$t('my-account.views.plan.data-confirmation-panel.phone')" label-for="phone">
          <b-input
            id="phone"
            :placeholder="$t('my-account.views.plan.data-confirmation-panel.phone')"
            v-model="form.phone"
            @blur="$v.form.phone.$touch()"
            :state="!$v.form.phone.$error ? null : false"
            v-mask="['(##) ####-####', '(##) #####-####']"
          >
          </b-input>
          <hs-form-invalid-feedback :state="!$v.form.phone.$error">
            <span v-if="!$v.form.phone.required">
              {{ $t('my-account.views.plan.data-confirmation-panel.required') }}
            </span>
            <span v-if="!$v.form.phone.minLength">
              {{ $t('my-account.views.plan.data-confirmation-panel.invalid') }}
            </span>
          </hs-form-invalid-feedback>
        </hs-group>
      </div>

      <hs-button
        :variant="isButtonDisabled ? 'secondary' : 'primary'"
        class="w-100 mt-3"
        @click="onConfirm"
        id="update-btn"
        :disabled="isButtonDisabled"
      >
        {{ $t('my-account.views.plan.data-confirmation-panel.next') }}
      </hs-button>
    </div>
  </div>
</template>

<script>
import { TheMask, mask } from 'vue-the-mask';
import { required, minLength } from 'vuelidate/lib/validators';
import customerService from '@/services/heimdall/customer';

import cepService from '@/services/cep';
import { mapState } from 'vuex';

export default {
  components: { TheMask },
  directives: { mask },
  props: {
    cep: {
      type: String,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        cpf: '',
        fullName: '',
        cep: this.cep,
        number: this.number,
        country: 'Brasil',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        complement: '',
        phone: this.phone,
      },
    };
  },
  async mounted() {
    await this.fillFormByCEP(this.cep);
  },
  methods: {
    async onCepChange(cep) {
      await this.fillFormByCEP(cep);
    },
    async fillFormByCEP(cep) {
      if (cep) {
        this.form.cep = cep;
        const response = await cepService.getLocation(cep);
        if (response && response.localidade) {
          this.form.city = response.localidade;
          this.form.street = response.logradouro;
          this.form.state = response.uf;
          this.form.complement = response.complemento;
          this.form.neighborhood = response.bairro;
          this.$v.form.cpf.$touch();
          this.$v.form.fullName.$touch();
          this.$v.form.number.$touch();
          this.$v.form.cep.$touch();
          this.$v.form.city.$touch();
          this.$v.form.country.$touch();
          this.$v.form.street.$touch();
          this.$v.form.state.$touch();
          this.$v.form.neighborhood.$touch();
        }
      }
    },
    async onConfirm() {
      const uid = await customerService.findOrCreateUid({ school: this.selectedSchool });

      await customerService.update({
        uid: uid,
        cpf: this.form.cpf,
        street: this.form.street,
        city: this.form.city,
        state: this.form.state,
        country: 'BR',
        zipcode: this.form.cep,
        phone: this.form.phone.slice(5).replace('-', ''),
        ddd: this.form.phone.slice(1, 3),
        neighborhood: this.form.neighborhood,
        complementary: this.form.complement,
        street_number: this.form.number,
      });
      this.$emit('next');
    },
  },
  watch: {
    'form.cep'(value) {
      this.fillFormByCEP(value);
    },
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    isButtonDisabled() {
      return this.$v.$invalid;
    },
  },
  validations: {
    form: {
      cpf: {
        required,
        minLength: minLength(14),
      },
      fullName: {
        required,
      },
      number: {
        required,
      },
      cep: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      street: {
        required,
      },
      state: {
        required,
      },
      neighborhood: {
        required,
      },
      phone: {
        required,
        minLength: minLength(14),
      },
    },
  },
};
</script>
