import axiosXavier from './axios';

export default {
  getPages() {
    return axiosXavier.get('api/view/pages').then(res => res.data);
  },

  postPage(slug: string, theme_id: string, published?: boolean) {
    return axiosXavier
      .post('api/view/pages', {
        page: {
          slug,
          published,
        },
        theme: theme_id,
      })
      .then(res => res.data);
  },

  putPage(page_id, published: boolean) {
    return axiosXavier
      .put(`api/view/pages/${page_id}`, {
        page: {
          published,
        },
      })
      .then(res => res.data);
  },
};
