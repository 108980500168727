<template>
  <div id="PaymentLoadingPanel" class="d-flex flex-column h-100 bg-white">
    <div class="hs-assign-panel__header p-4 d-flex justify-content-between align-items-center">
      <h4 class="font-weight-bold">{{ $t('my-account.views.plan.signature-panel.title') }}</h4>
      <span class="text-primary pointer hs-text-36 hs-lh-0" @click="$emit('close')">×</span>
    </div>
    <div class="d-flex flex-column justify-content-between" style="min-height:525px">
      <div class="d-flex flex-column justify-content-center align-items-center mt-5 px-5">
        <img :src="require('@/assets/images/robot-empty.svg')" width="150" />
        <div class="mt-5 text-center d-flex flex-column align-items-center">
          <span class="font-weight-bold">
            {{ $t('my-account.views.plan.payment-loading-panel.content') }}
          </span>
          <span>{{ $t('my-account.views.plan.payment-loading-panel.content-2') }}</span>
        </div>
        <hs-loading-icon cherry class="mt-5" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
