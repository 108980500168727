<template>
  <div id="PaymentSuccessPanel" class="d-flex flex-column h-100">
    <div class="bg-pale-cyan px-4 pt-4 d-flex justify-content-center align-items-center">
      <img alt="payment-success" :src="require('@/assets/images/my_account/magnet-success.svg')" />
    </div>
    <div class="d-flex flex-column justify-content-between bg-white p-4 h-100" style="min-height:330px">
      <div class="d-flex flex-column justify-content-start align-items-center">
        <h3 class="font-weight-bold text-center">
          {{ $t('my-account.views.plan.payment-success-panel.title') }}
        </h3>
        <span class="mt-2 mb-1 hs-text-18 text-center">
          {{ $t('my-account.views.plan.payment-success-panel.subtitle') }}
        </span>
        <div class="d-flex w-100 mt-3">
          <div class="w-50">
            <hs-group
              :label="$t('my-account.views.plan.payment-success-panel.cep')"
              label-for="cep"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <the-mask
                id="cep"
                placeholder="_____-___"
                class="form-control hs-credit-card-panel__input"
                :mask="'#####-###'"
                :masked="true"
                v-model="cep"
              />
            </hs-group>
          </div>
          <div class="w-50 ml-3">
            <hs-group
              :label="$t('my-account.views.plan.payment-success-panel.number')"
              label-for="number"
              label-class="hs-credit-card-panel__input-label"
              class="mb-2"
            >
              <hs-input
                id="number"
                v-model="number"
                :state="$v.number.$error ? false : null"
                @blur="$v.number.$touch()"
              />
            </hs-group>
            <hs-form-invalid-feedback :state="!$v.number.$error">
              <span class="font-size-xs" v-if="!$v.number.required">
                {{ $t('my-account.views.plan.payment-success-panel.required') }}
              </span>
            </hs-form-invalid-feedback>
          </div>
        </div>

        <hs-group
          :label="$t('my-account.views.plan.payment-success-panel.phone')"
          label-for="phone"
          label-class="hs-credit-card-panel__input-label"
          class="w-100 mt-2"
        >
          <b-input
            id="phone"
            :placeholder="$t('my-account.views.plan.payment-success-panel.phone')"
            v-model="phone"
            @blur="$v.phone.$touch()"
            :state="!$v.phone.$error ? null : false"
            v-mask="['(##) ####-####', '(##) #####-####']"
          >
          </b-input>
          <hs-form-invalid-feedback :state="!$v.phone.$error">
            <span v-if="!$v.phone.required">
              {{ $t('my-account.views.plan.payment-success-panel.required') }}
            </span>
            <span v-if="!$v.phone.minLength">
              {{ $t('my-account.views.plan.payment-success-panel.invalid') }}
            </span>
          </hs-form-invalid-feedback>
        </hs-group>
      </div>
      <div class="d-flex justify-content-end mt-3 w-100">
        <hs-button
          :variant="isButtonDisabled ? 'secondary' : 'primary'"
          class="w-100"
          @click="goNextStep"
          :disabled="isButtonDisabled"
        >
          {{ $t('my-account.views.plan.payment-success-panel.next') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mask, TheMask } from 'vue-the-mask';
import { required, minLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  props: {
    plan: Object,
  },
  components: { TheMask },
  directives: { mask },
  data() {
    return {
      cep: '',
      number: '',
      phone: '',
    };
  },
  mounted() {
    this.phone = this.selectedSchool && this.selectedSchool.phone;
  },
  methods: {
    goNextStep() {
      this.$emit('next', { cep: this.cep, number: this.number, phone: this.phone });
    },
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    isButtonDisabled() {
      return this.$v.$invalid;
    },
  },
  validations: {
    number: {
      required,
    },
    cep: {
      required,
      minLength: minLength(9),
    },
    phone: {
      required,
      minLength: minLength(14),
    },
  },
};
</script>

<style lang="scss">
.hs-payment-success__close-button {
  top: 30px;
  right: 20px;
}
</style>
