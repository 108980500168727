<template>
  <section class="tlw-flex tlw-items-center lg:tlw-px-48 tlw-py-20 tlw-container">
    <main class="tlw-w-full lg:tlw-w-1/2">
      <h2 class="tlw-mt-5 tlw-text-3xl tlw-font-bold">
        {{ $t('my-account.views.plan.plans-more-info.question') }}
      </h2>
      <p class="tlw-mt-5 tlw-text-xl">
        {{ $t('my-account.views.plan.plans-more-info.description') }}
      </p>
      <button
        data-test="more-info"
        class="tlw-py-3 tlw-px-5 tlw-rounded tlw-mt-5 tlw-transition-all tlw-ease-in-out tlw-font-extrabold tlw-bg-purple-500 tlw-text-white hover:tlw-bg-purple-600"
        @click="onClick"
      >
        {{ $t('my-account.views.plan.plans-more-info.button-label') }}
      </button>
    </main>
    <aside class="tlw-hidden lg:tlw-block tlw-w-1/2 tlw-text-center">
      <img
        class="tlw-w-1/2 tlw-mx-auto"
        src="@/assets/images/myspark/bot2.svg"
        :alt="$t('my-account.views.plan.plan-hero-header.label')"
      />
    </aside>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService } from '@/services';
import BlipchatService from '@/services/blipchat';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export default {
  name: 'PlansMoreInfo',
  methods: {
    onClick() {
      return this.openChatSales();
    },
    openChatSales() {
      analyticsService.track({
        event: 'opened chat sales',
        props: {},
      });
      BlipchatService.open(this.selectedSchool, this.actualPlan);
    },
  },
  computed: {
    ...mapState('plan', ['actualPlan']),
    ...mapState('school', ['selectedSchool']),
  },
};
</script>
