<template>
  <div class="overflow-x-auto">
    <table class="d-none d-lg-table hs-plan-invoices-table w-100 mt-4">
      <thead class="hs-bg-grey-20">
        <tr>
          <th>{{ $t('my-account.views.plan-invoices.plan') }}</th>
          <th>{{ $t('my-account.views.plan-invoices.paid-at') }}</th>
          <th>{{ $t('my-account.views.plan-invoices.payment') }}</th>
          <th>{{ $t('my-account.views.plan-invoices.total') }}</th>
          <th style="width: 140px"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(invoice, index) in invoices"
          :key="`invoice_${index}`"
          class="hs-plan-invoices-table__row"
          @mouseover="hoveringRow = index"
          @mouseleave="hoveringRow = -1"
        >
          <td>{{ $t('my-account.views.plan.title') }} Growth</td>
          <td>{{ formatDate(invoice.date) }}</td>
          <td>
            <div class="d-flex align-items-center">
              <hs-icon class="mr-2" icon="credit-card" v-if="!invoice.cardInfo.brand" size="24" />
              <img
                v-else
                class="mr-2"
                :src="
                  `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${invoice.cardInfo.brand}.svg`
                "
                width="30"
              />
              <span>{{ invoice.cardInfo.lastDigits || '****' }}</span>
            </div>
          </td>
          <td>{{ invoice.currency || 'R$' }} {{ (invoice.amount / 100).toFixed(2) }}</td>
          <td>
            <div class="d-flex justify-content-end" v-if="hoveringRow === index">
              <a
                class="hs-plan-invoices-table__icon-wrapper mr-3 px-2 pointer rounded-circle d-flex align-items-center justify-content-center"
                v-b-tooltip.hover.bottom
                title="Ver fatura"
                @click="onInvoiceClick(invoice)"
              >
                <hs-icon icon="eye" class=" text-primary" size="20" />
              </a>
              <!-- <a
                class="hs-plan-invoices-table__icon-wrapper px-2 pointer rounded-circle d-flex align-items-center justify-content-center"
                v-b-tooltip.hover.bottom
                title="Baixar nota fiscal"
                @click="onInvoiceClick(invoice)"
              >
                <hs-icon icon="download" class="text-primary" size="20" />
              </a> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex d-lg-none flex-column pt-4">
      <div
        v-for="(invoice, index) in invoices"
        :key="`invoice_${index}`"
        class="hs-plan-invoices-table__mobile-item d-flex flex-column bg-white p-3 mb-2"
      >
        <div class="d-flex justify-content-between">
          <span>{{ $t('my-account.views.plan.title') }} Growth</span>
          <div class="d-flex align-items-center">
            <img
              class="mr-2"
              :src="`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${invoice.cardInfo.brand}.svg`"
              width="25"
            />
            <span class="hs-text-grey-30">{{ invoice.cardInfo.lastDigits }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-2">
          <span> {{ formatDate(invoice.date) }} </span>
          <span class="hs-text-grey-30"> {{ invoice.currency || 'R$' }} {{ (invoice.amount / 100).toFixed(2) }} </span>
        </div>

        <hs-button
          @click="onInvoiceClick(invoice)"
          variant="primary"
          class="d-flex align-items-center justify-content-center mt-3"
        >
          <hs-icon icon="eye" class="text-white mr-2" size="20" />
          {{ $t('my-account.views.plan-invoices.see-invoice') }}
        </hs-button>
      </div>
    </div>
    <InvoiceModal
      v-if="invoiceSelected"
      :invoice="invoiceSelected"
      :visible="invoiceModalVisible"
      @close="invoiceModalVisible = false"
    />
  </div>
</template>

<script>
import datesHelper from '@/shared/helpers/dates';
import InvoiceModal from './InvoiceModal.vue';

export default {
  components: { InvoiceModal },
  props: {
    invoices: Array,
    default: () => [],
  },
  data() {
    return {
      hoveringRow: -1,
      invoiceSelected: null,
      invoiceModalVisible: false,
    };
  },
  methods: {
    formatDate(date) {
      return datesHelper.format({
        date,
        format: 'DD MMM YYYY',
      });
    },
    onInvoiceClick(invoice) {
      this.invoiceSelected = invoice;
      this.invoiceModalVisible = true;
    },
  },
};
</script>

<style lang="scss">
.hs-plan-invoices-table th,
.hs-plan-invoices-table td {
  padding: 0.85rem 1.5rem;
}
.hs-plan-invoices-table__row {
  height: 65px;
}

.hs-plan-invoices-table__row:hover {
  background: $light-blue;
}
.hs-plan-invoices-table__icon-wrapper {
  width: 35px;
  height: 35px;
}
.hs-plan-invoices-table__icon-wrapper:hover {
  background: $grey-20;
}
.hs-plan-invoices-table__mobile-item {
  border: 0.5px solid #a8a8a8;
  border-radius: 4px;
}
</style>
