<template>
  <div>
    <hsModal :id="modalId" :size="'lg'" hide-header @hidden="hidden">
      <hs-loading v-if="isLoading" />
      <template v-else>
        <hsForm @submit="save">
          <hs-group
            :label="$t('my-account.views.domains.components.add-domain-modal.inputs.name.label')"
            label-for="new-domain"
            label-class="font-weight-bold"
          >
            <hs-input
              id="new-domain"
              :placeholder="$t('my-account.views.domains.components.add-domain-modal.inputs.name.placeholder')"
              :formatter="formatter"
              v-model="form.newDomain"
              :state="!$v.form.newDomain.$error ? null : false"
              @blur="$v.form.newDomain.$touch()"
            >
              <template slot="feedback" v-if="$v.form.newDomain.$error">
                <hs-form-invalid-feedback :state="false">
                  <span v-if="!$v.form.newDomain.checkHttp">
                    {{ $t('my-account.views.domains.components.add-domain-modal.inputs.name.validations.http') }}
                  </span>
                  <span v-else-if="!$v.form.newDomain.checkMyedools">
                    {{ $t('my-account.views.domains.components.add-domain-modal.inputs.name.validations.myedools') }}
                  </span>
                  <span v-else-if="!$v.form.newDomain.checkAccents">
                    {{ $t('my-account.views.domains.components.add-domain-modal.inputs.name.validations.accents') }}
                  </span>
                  <span v-else-if="!$v.form.newDomain.checkUrl">
                    {{ $t('my-account.views.domains.components.add-domain-modal.inputs.name.validations.url') }}
                  </span>
                </hs-form-invalid-feedback>
              </template>
            </hs-input>
          </hs-group>

          <hs-checkbox class="mb-3" v-model="form.confirm" @blur="$v.form.confirm.$touch()">
            <p v-html="$t('my-account.views.domains.components.add-domain-modal.inputs.confirm.label')"></p>
          </hs-checkbox>

          <div class="d-flex flex-column flex-md-row align-items-center">
            <hs-button variant="secondary" @click="$bvModal.hide(modalId)">
              {{ $t('actions.cancel') }}
            </hs-button>
            <hs-button id="save-button" variant="primary" type="submit" class="ml-2" :disabled="$v.form.$invalid">
              {{ $t('my-account.views.domains.components.add-domain-modal.button-text') }}
            </hs-button>
          </div>
        </hsForm>
      </template>
    </hsModal>
  </div>
</template>

<script>
import { hsForm, hsLoading, hsModal } from '@/components';
import { mapState, mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import { analyticsService } from '@/services';

export default {
  name: 'AddDomainModal',
  components: {
    hsForm,
    hsLoading,
    hsModal,
  },
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
    ...mapState('school', ['selectedSchool']),
    modalId() {
      return 'add-domain-name-modal';
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        newDomain: '',
        confirm: false,
      },
    };
  },
  validations: {
    form: {
      newDomain: {
        required,
        checkHttp: value => !value.match(/^(http|https):\/\//gim),
        checkMyedools: function(value) {
          const pattern = new RegExp(`${process.env.VUE_APP_DOMAIN_URL}$`, 'gim');
          if (value.match(pattern)) {
            return value.toLowerCase() === this.getDefaultDomain.toLowerCase();
          }
          return true;
        },
        checkUrl: value => !!value.match(/^[a-z0-9.-].+[a-z0-9-]\.*[a-z0-9-]\.[a-z]{1,}$/gm),
        checkAccents: value => !value.match(/[^a-zA-Z0-9-.]+/g),
      },
      confirm: {
        checkIfTrue: value => value === true,
      },
    },
  },
  methods: {
    ...mapActions('school', ['addDomain', 'addDomainToSchool']),
    formatter(value) {
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      return value;
    },
    hidden() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },
    async save() {
      this.isLoading = true;

      this.addDomainToSchool({
        newDomain: this.form.newDomain,
        schoolId: this.selectedSchool.id,
      })
        .then(
          () => {
            this.hidden();
            ToastHelper.successMessage(this.$t('my-account.views.domains.components.add-domain-modal.toast.success'));
            this.$emit('created-domain');
            this.$bvModal.hide(this.modalId);
            analyticsService.track({
              event: 'Domain added',
            });
          },
          () => ToastHelper.dangerMessage(this.$t('my-account.views.domains.components.add-domain-modal.toast.error'))
        )
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
