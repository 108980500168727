<template>
  <div class="w-100 d-flex flex-column px-4 pb-3 pt-4 justify-content-between">
    <div class="w-100 pb-4">
      <h5>
        {{ $t('my-account.views.plan.actual-plan-panel.subtitle') }}
      </h5>
    </div>
    <FeaturesListing :members-free="[]" :funnels-free="funnelsFeaturesFree" :funnels-premium="funnelsFeaturesPremium" />
  </div>
</template>

<script>
import FeaturesListing from './FeaturesListing';
export default {
  components: { FeaturesListing },
  computed: {
    funnelsFeaturesFree() {
      return new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-free-features.${index}`),
      }));
    },
    funnelsFeaturesPremium() {
      return new Array(6).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.funnels-pro-perpetual-features.${index}`),
      }));
    },
    membersFeaturesFree() {
      return new Array(3).fill().map((_, index) => ({
        title: this.$t(`my-account.views.plan.actual-plan-panel.members-free-features.${index}`),
      }));
    },
  },
};
</script>

<style lang="scss">
.hs-plan-panel__features-container {
  max-width: 650px;
}
.hs-plan-panel__header-image {
  width: 80px;
  top: 10px;
  right: 10px;
}
@media screen and (min-width: $screen-xs) {
  .hs-plan-panel__header-image {
    width: 120px;
    top: -35px;
  }
}

.hs-plan-topic {
  height: 10px;
  width: 10px;
}
</style>
