<template>
  <div class="d-flex tlw-flex-wrap lg:tlw-flex-nowrap col-12">
    <PlanListingItem
      data-id="plan-listing-item"
      v-for="currentPlan in plans"
      :key="currentPlan.slug"
      :plan="currentPlan"
      class="mr-sm-2 mb-4 mb-lg-0 tlw-flex-grow"
      :selected="currentPlan.slug === plan.slug"
      @assign="data => $emit('assign', data)"
      :downgradable="isDowngradable(currentPlan)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import planService from '@/services/heimdall/newPlans';
import PlanListingItem from './PlanListingItem';

export default {
  props: ['plan'],
  components: { PlanListingItem },
  data() {
    return {
      plans: planService.getActivePlans(),
    };
  },
  computed: {
    ...mapState('plan', ['actualPlan']),
    ...mapState('school', ['selectedSchool']),
  },
  methods: {
    isDowngradable(plan) {
      return planService.canDowngrade(this.plan, plan);
    },
  },
};
</script>
