<template>
  <div class="hs-plan-panel__features-container d-flex flex-column flex-lg-row justify-content-between">
    <div>
      <FeaturesListingItem v-for="feature in funnelsFree" :key="feature.title" :feature="feature" />
      <FeaturesListingItem v-for="feature in funnelsPremium" :key="feature.title" :feature="feature" is-premium />
    </div>

    <div class="mt-3 mt-lg-0" v-if="membersFree.length || membersPremium.length">
      <FeaturesListingItem v-for="feature in membersFree" :key="feature.title" :feature="feature" />
      <FeaturesListingItem v-for="feature in membersPremium" :key="feature.title" :feature="feature" is-premium />
    </div>
  </div>
</template>

<script>
import FeaturesListingItem from './FeaturesListingItem';
export default {
  components: { FeaturesListingItem },
  props: {
    funnelsFree: {
      type: Array,
      default: () => [],
    },
    membersFree: {
      type: Array,
      default: () => [],
    },
    funnelsPremium: {
      type: Array,
      default: () => [],
    },
    membersPremium: {
      type: Array,
      default: () => [],
    },
    useIcon: {
      type: Boolean,
    },
  },
};
</script>

<style>
.hs-plan-panel__features-container {
  max-width: 650px;
}
</style>
