<template>
  <section class="d-flex position-relative flex-fill flex-column">
    <hsPageHeader
      class="d-none d-lg-block"
      back-route="MyAccountDashboard"
      :title="$t('breadcrumb.MyAccountPlan')"
      :back-text="$t('my-account.views.dashboard.title')"
    />
    <MobileTabs class="d-block d-lg-none" :plan="actualPlan" v-if="actualPlan" />
    <div class="d-none d-lg-flex flex-column flex-lg-row m-2 m-lg-5 pr-lg-5 px-2 px-lg-0" v-if="actualPlan">
      <div class="col-12 col-lg-7 px-0 mr-5">
        <ActualPlanPanel data-id="actual-plan" :plan="actualPlan" v-if="actualPlan" />
        <div class="d-flex flex-column" data-id="next-plan" v-if="nextPlan">
          <h4 class="mt-5">
            {{ $t('my-account.views.plan.plan-offer') }}
          </h4>
          <hr class="w-100" />
          <NextPlanBanner data-id="next-plan-component" @assign="() => onPlanAssign(nextPlan)" :plan="nextPlan" />
          <router-link
            :to="{ name: 'MyAccountPlans' }"
            class="mt-3 pointer btn pb-3 pr-4 pl-0 text-cherry underline--hover text-left"
          >
            {{ $t('my-account.views.plan.plan-listing-link') }} >
          </router-link>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 px-0 mt-2 mt-lg-0">
        <SignaturePanel data-id="signature-plan" :plan="actualPlan" v-if="!assignPanelVisible" />
        <AssignPanel v-else data-id="assign-panel" :plan="planToAssign" @close="assignPanelVisible = false" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import planService from '@/services/heimdall/newPlans';
import hsPageHeader from '@/components/_structures/PageHeader';
import ActualPlanPanel from './components/ActualPlanPanel/index';
import NextPlanBanner from './components/NextPlanBanner';
import SignaturePanel from './components/SignaturePanel/index';
import AssignPanel from './components/AssignPanel/index';
import MobileTabs from './components/MobileTabs';
import TrackingHelper from '@/shared/helpers/tracking';

export default {
  name: 'MyAccountPlan',
  components: {
    hsPageHeader,
    ActualPlanPanel,
    NextPlanBanner,
    SignaturePanel,
    MobileTabs,
    AssignPanel,
  },
  data() {
    return {
      assignPanelVisible: false,
      planToAssign: null,
    };
  },
  async mounted() {
    if (!this.actualPlan) {
      await this.refreshActualPlan(this.selectedSchool);
    }

    TrackingHelper.trackPlanViewed();
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('plan', ['actualPlan']),
    nextPlan() {
      if (this.actualPlan) {
        return planService.getNextPlan(this.actualPlan);
      }
      return null;
    },
  },
  methods: {
    ...mapActions('plan', ['refreshActualPlan']),
    onPlanAssign(plan) {
      this.planToAssign = plan;
      this.assignPanelVisible = true;
    },
  },
};
</script>
