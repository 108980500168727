export default [
  {
    index: 1,
    question: 'Existe fidelidade nos planos?',
    answer: ['Não! Você tem a liberdade de cancelar na hora que desejar.'],
  },
  {
    index: 2,
    question: 'Eu posso migrar de plano a qualquer momento? ',
    answer: [
      'Pode! É só clicar em "Quero esse plano" no plano desejado e abrir as portas para o crescimento do seu projeto.',
    ],
  },
  {
    index: 3,
    question: 'E se eu quiser voltar pra um plano menor, pode? O que acontece com as minhas funcionalidades?  ',
    answer: [
      'Pode! Você pode tanto subir para um plano quanto voltar para o anterior quando desejar.',
      'Ao sair de um plano para outro menor as funcionalidades que não pertencem ao plano para o qual você está indo serão desativadas.',
      'Então fique atento e entenda se essas funcionalidades não são essenciais ao seu projeto antes de prosseguir com a mudança ok?',
    ],
  },
  {
    index: 4,
    question: 'Eu posso testar um plano pago antes de assinar? ',
    answer: [
      'Claro! É só falar com os nossos consultores o plano que deseja testar e liberamos 7 dias de testes para você.',
    ],
  },
  {
    index: 5,
    question: 'Se eu me arrepender de ter assinado tem reembolso? ',
    answer: [
      'Sim! Apesar do nosso serviço não se encaixar na regra de 7 dias do CDC, a Hero tem uma política de confiança que garante a devolução em caso de arrependimento em até 10 dias após a contratação.',
    ],
  },
  {
    index: 6,
    question: 'E se eu tiver mais dúvidas? ',
    answer: [
      'Aqui temos dois caminhos. Se você está com dúvidas nos planos e suas funcionalidades e quer entender o que se encaixa melhor no seu projeto é só acionar o nosso time no botão acima (falar com consultor de vendas) e um dos nossos especialistas irá atendê-lo.',
      'Nós atendemos via chat de segunda à sexta, das 9h às 18h.',
      'Agora, se você já está no plano adequado e tem dúvidas sobre as configurações da ferramenta, é só acionar o nosso time de suporte pelo chat ou buscar ajuda na nossa base de ajuda. Ambos ficam no menu inferior esquerdo.',
      'Nós temos um time de heroínas e heróis preparados para ajudá-lo, além de conteúdos incríveis que vão te ajudar não só no uso da plataforma quanto na sua estratégia de negócio.',
    ],
  },
];
