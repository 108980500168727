







































import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/components/_structures/PageHeader.vue';
import PremiumBadge from '@/components/PremiumBadge/index.vue';

@Component({
  name: 'MyAccountDashboard',
  components: {
    PageHeader,
    PremiumBadge,
    HsPremiumModal: () => import('@/components/PremiumModal.vue'),
  },
})
export default class Dashboard extends Vue {
  items: any[] = [];

  get canCreateANewUserAdmin() {
    return this.$FCL.canCreateANewUserAdmin();
  }

  get canActiveThemeView() {
    return this.$FCL.canActiveThemeView() && this.$ACL.canThemeView();
  }

  get canActiveShowCaseHero() {
    return this.$FCL.canActiveShowCaseHero();
  }

  get canActiveNetflixHero() {
    return this.$FCL.canActiveNetflixHero();
  }

  created() {
    this.items = [
      {
        title: this.$t('my-account.views.dashboard.items.account-settings.title'),
        subtitle: this.$t('my-account.views.dashboard.items.account-settings.subtitle'),
        icon: 'cog',
        permission: true,
        route: 'MyAccountSettings',
        visible: this.$ACL.canMyAccountBusiness(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.domains.title'),
        subtitle: this.$t('my-account.views.dashboard.items.domains.subtitle'),
        icon: 'globe',
        permission: true,
        route: 'MyAccountDomains',
        visible: this.$ACL.canMyAccountDomains(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.plan.title'),
        subtitle: this.$t('my-account.views.dashboard.items.plan.subtitle'),
        icon: 'star',
        permission: true,
        route: 'MyAccountPlan',
        visible: this.$ACL.canMyAccountPlans(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.my-team.title'),
        subtitle: this.$t('my-account.views.dashboard.items.my-team.subtitle', { qtd: 2 }),
        icon: 'users',
        new: true,
        permission: this.canCreateANewUserAdmin,
        route: 'MyTeam',
        visible: this.$ACL.canMyAccountTeam(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.theme.title'),
        subtitle: this.$t('my-account.views.dashboard.items.theme.subtitle'),
        permission: this.canActiveThemeView,
        icon: 'browser',
        route: 'MyAccountTheme',
        visible: this.canActiveThemeView,
      },
      {
        title: this.$t('my-account.views.dashboard.items.vitrine.title'),
        subtitle: this.$t('my-account.views.dashboard.items.vitrine.subtitle'),
        permission: this.canActiveShowCaseHero,
        icon: 'store-alt',
        route: 'Vitrine',
        visible: this.$ACL.canMyAccountVitrine(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.student-area.title'),
        subtitle: this.$t('my-account.views.dashboard.items.student-area.subtitle'),
        permission: this.canActiveNetflixHero,
        icon: 'user-graduate',
        route: 'AreaAluno',
        visible: this.canActiveNetflixHero && this.$ACL.canMyAccountStudent(),
      },
      {
        title: this.$t('my-account.views.dashboard.items.global-coupon.title'),
        subtitle: this.$t('my-account.views.dashboard.items.global-coupon.subtitle'),
        icon: 'ticket-alt',
        new: true,
        permission: true,
        route: 'GlobalCoupon',
        visible: true,
      },
    ];
  }

  onClick(permission: boolean, route: string) {
    if (permission) {
      this.$router.push({ name: route });
    } else {
      this.$bvModal.show('premium-modal');
    }
  }

  requestPremiumFeature() {
    this.$router.push({ name: 'MyAccountPlans' });
    this.$bvModal.hide('premium-modal');
  }
}
