<template>
  <div id="SignatureCompletePanel" class="d-flex flex-column h-100">
    <div class="bg-pale-cyan px-4 pt-4 d-flex justify-content-center align-items-center pb-3">
      <img alt="payment-success" :src="require('@/assets/images/authority.svg')" />
    </div>
    <div
      class="d-flex flex-column justify-content-between align-items-center bg-white p-4 h-100"
      style="min-height:330px"
    >
      <div class="d-flex flex-column justify-content-start align-items-center">
        <h3>{{ $t('my-account.views.plan.signature-complete-panel.title') }}</h3>
        <h2 class="font-weight-bold mt-3">{{ plan.title }}</h2>
        <span class="mt-4 text-center px-3">
          {{ $t('my-account.views.plan.signature-complete-panel.subtitle') }}
        </span>
      </div>
      <div class="d-flex justify-content-end mt-3 w-100">
        <hs-button variant="primary" class="w-100 pointer" @click="onGoToNewPlan">
          {{ $t('my-account.views.plan.signature-complete-panel.next') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    plan: Object,
  },
  methods: {
    ...mapActions('plan', ['refreshActualPlan']),
    onGoToNewPlan() {
      this.refreshActualPlan(this.selectedSchool);
      this.$emit('close');
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
};
</script>
