<template>
  <article
    data-test="faq-item"
    role="button"
    class="tlw-bg-white tlw-rounded-sm tlw-p-5 tlw-flex tlw-relative"
    @click="isOpened = !isOpened"
    tabindex="0"
  >
    <hs-icon
      class="tlw-absolute tlw-top-2 tlw-right-2"
      variant="light"
      size="20"
      :icon="isOpened ? 'chevron-up' : 'chevron-down'"
    />
    <aside class="tlw-w-md">
      <img class="tlw-w-full" src="@/assets/images/commons/magnifier.svg" alt="F.A.Q" />
    </aside>
    <main class="tlw-flex-grow tlw-ml-5">
      <p data-test="formatted-question" class="tlw-font-extrabold tlw-font-xl">
        {{ formattedQuestion }}
      </p>
      <div v-if="isOpened">
        <p data-test="answers" class="tlw-mt-5 tlw-font-xl" :key="index" v-for="(paragraph, index) in question.answer">
          {{ paragraph }}
        </p>
      </div>
    </main>
  </article>
</template>

<script>
export default {
  name: 'PlansFrequentAsksItem',
  data() {
    return {
      isOpened: false,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedQuestion() {
      return `${this.question.index} - ${this.question.question}`;
    },
  },
};
</script>
