<template>
  <section v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </section>
  <div class="container-fluid p-0" v-else>
    <div class="row no-gutters py-3 py-lg-4 px-4 px-lg-5 bg-white align-items-center">
      <div class="col">
        <router-link :to="{ name: 'MyAccountDashboard' }" class="text-dark font-size-sm text-decoration-none">
          <hs-icon icon="long-arrow-left" /> {{ selectedSchool.name }}
        </router-link>
        <h3 class="font-weight-bold">{{ $t('breadcrumb.MyAccountPersonalData') }}</h3>
      </div>
    </div>
    <div class="row my-3 my-lg-5 mx-1 mx-lg-4">
      <div class="col-lg-8">
        <div class="bg-white rounded-lg p-4">
          <div class="row">
            <div class="col-md-6">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.first-name.label')"
                label-for="first-name"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <hs-input
                  id="first-name"
                  :placeholder="$t('my-account.views.personal-data.inputs.first-name.placeholder')"
                  v-model="personalData.first_name"
                  :state="!$v.personalData.first_name.$error ? null : false"
                  @blur="$v.personalData.first_name.$touch()"
                />
              </hs-group>
            </div>
            <div class="col-md-6">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.last-name.label')"
                label-for="last-name"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <hs-input
                  id="last-name"
                  :placeholder="$t('my-account.views.personal-data.inputs.last-name.placeholder')"
                  v-model="personalData.last_name"
                />
              </hs-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.email.label')"
                label-for="email"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <hs-input
                  id="email"
                  type="email"
                  autocomplete="nope"
                  :placeholder="$t('my-account.views.personal-data.inputs.email.placeholder')"
                  :text="$t('my-account.views.personal-data.inputs.email.info')"
                  v-model="personalData.email"
                  :state="!$v.personalData.email.$error ? null : false"
                  @blur="$v.personalData.email.$touch()"
                />
              </hs-group>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label class="font-weight-bold mb-0">{{
                $t('my-account.views.personal-data.inputs.cover-image-url.label')
              }}</label>
              <p class="font-size-xs">{{ $t('my-account.views.personal-data.inputs.cover-image-url.placeholder') }}</p>
              <div class="d-flex flex-wrap mt-1">
                <div class="position-relative mr-2">
                  <div class="position-absolute w-100 text-right">
                    <hs-icon class="text-primary mr-2" variant="solid" icon="check-circle" />
                  </div>
                  <b-img-lazy
                    height="91"
                    width="91"
                    class="border-primary rounded-lg border-2"
                    :src="personalData.cover_image_url ? personalData.cover_image_url : ''"
                  />
                </div>
                <label
                  for="img-input"
                  class="d-flex flex-column justify-content-center rounded-lg border-2 border-dashed border-primary uploader"
                  @dragover.prevent
                  @drop.prevent="onFile"
                >
                  <div class="text-center" v-if="isUploading">
                    <b-spinner small type="grow" variant="primary" />
                  </div>
                  <template v-else>
                    <div class="text-center">
                      <p class="font-size-xs">{{ $t('my-account.views.personal-data.inputs.cover-image-url.info') }}</p>
                      <hs-icon class="font-size-lg" variant="light" icon="cloud-upload" />
                    </div>
                  </template>
                  <input id="img-input" type="file" class="d-none" @input="onFile" />
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-3 col-md-2">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.ddd.label')"
                label-for="ddd"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <the-mask
                  id="ddd"
                  placeholder="(__)"
                  class="form-control"
                  :mask="'(##)'"
                  :masked="false"
                  v-model="personalData.ddd"
                />
              </hs-group>
            </div>
            <div class="col-sm-4 col-md-5">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.phone.label')"
                label-for="phone"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <the-mask
                  id="phone"
                  placeholder="_____ - ___"
                  class="form-control"
                  :mask="'##### - ####'"
                  :masked="false"
                  v-model="personalData.phone"
                />
              </hs-group>
            </div>
            <div class="col-sm-5">
              <hs-group
                :label="$t('my-account.views.personal-data.inputs.cpf.label')"
                label-for="cpf"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <b-form-input
                  v-mask="'###.###.###-##'"
                  placeholder="___.___.___-__"
                  v-model="personalData.cpf"
                  @blur.native="$v.personalData.cpf.$touch()"
                  :state="!$v.personalData.cpf.$error ? null : false"
                  class="form-control"
                ></b-form-input>

                <template v-if="$v.personalData.cpf.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.personalData.cpf.required">
                    {{ $t('sparkfunnels.invite.create.form.validation.cpf') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-group>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <hs-button
              :variant="$v.personalData.$invalid || isUploading ? 'light' : 'primary'"
              :disabled="$v.personalData.$invalid || isUploading"
              @click="onEdit('personal')"
            >
              <b-spinner small type="grow" v-if="isUploading" />
              {{
                isUploading
                  ? $t('my-account.views.personal-data.buttons-text.saving')
                  : $t('my-account.views.personal-data.buttons-text.save')
              }}
            </hs-button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-4 mt-lg-0">
        <div class="bg-white rounded-lg p-4">
          <h5 class="font-weight-bold mb-3">
            {{ $t('my-account.views.personal-data.change-password-title') }}
          </h5>
          <hs-group
            :label="$t('my-account.views.personal-data.inputs.password.label')"
            label-for="password"
            label-class="font-weight-bold"
            class="mb-2"
          >
            <hs-input
              id="password"
              :type="password.type"
              placeholder="******"
              :icon="password.icon"
              v-model="passwordData.password"
              :nestedAction="
                () => {
                  showHidePassword('password');
                }
              "
            />
          </hs-group>
          <hs-group
            :label="$t('my-account.views.personal-data.inputs.confirm-password.label')"
            label-for="confirm-password"
            label-class="font-weight-bold"
            class="mb-4"
          >
            <hs-input
              id="confirm-password"
              :type="confirmPassword.type"
              placeholder="******"
              :icon="confirmPassword.icon"
              v-model="passwordData.password_confirmation"
              :nestedAction="
                () => {
                  showHidePassword('confirmPassword');
                }
              "
            />
          </hs-group>
          <div class="d-flex justify-content-end">
            <hs-button
              :variant="$v.passwordData.$invalid ? 'light' : 'outline-secondary'"
              :disabled="$v.passwordData.$invalid"
              @click="onEdit('password')"
            >
              {{ $t('my-account.views.personal-data.buttons-text.change') }}
            </hs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';
import { mask } from 'vue-the-mask';
import { userService, uploadService, customerService } from '@/services';
import { hsLoading } from '@/components';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import { isValidCPF } from '@brazilian-utils/brazilian-utils';

export default {
  name: 'MyAccountPersonalData',
  components: {
    hsLoading,
    TheMask,
  },
  directives: { mask },
  validations: {
    personalData: {
      first_name: {
        required,
      },
      email: {
        required,
        email,
      },
      cpf: {
        isValidCPF,
      },
    },
    passwordData: {
      password: {
        required,
      },
      password_confirmation: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  data() {
    return {
      password: {
        type: 'password',
        icon: 'eye',
      },
      confirmPassword: {
        type: 'password',
        icon: 'eye',
      },
      isLoading: true,
      isUploading: false,
      personalData: {},
      passwordData: {},
    };
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
  },
  methods: {
    ...mapActions('auth', ['updateUser']),
    showHidePassword(prop) {
      this.$data[prop].type = this.$data[prop].type === 'password' ? 'text' : 'password';
      this.$data[prop].icon = this.$data[prop].type === 'password' ? 'eye' : 'eye-slash';
    },
    async onFile(e) {
      this.isUploading = true;
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.selectedSchool.id);
        this.personalData.cover_image_url = await uploadService.upload(data.url, data.fields, file);

        this.onEdit('personal');
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('my-account.views.personal-data.toast.error'));
      } finally {
        this.isUploading = false;
      }
    },
    async onEdit(type) {
      try {
        this.isUploading = true;
        let payload = {};
        if (['personal'].includes(type)) {
          payload = { ...this.personalData };
          payload.phone = `${payload.ddd}${payload.phone}`;
          payload.cpf = payload.cpf ? payload.cpf.replace(/\D+/g, '') : '';
          await this.updateUser(payload);

          const uid = await customerService.findOrCreateUid({ school: this.selectedSchool });
          if (uid)
            await customerService.update({
              uid: uid,
              name: `${payload.first_name} ${payload.last_name || ''}`,
              email: payload.email,
            });
        } else {
          payload = { id: this.loggedUser.id, ...this.passwordData };
          await this.updateUser(payload);
        }

        ToastHelper.successMessage(this.$t('my-account.views.personal-data.toast.success'));
      } catch ({ response }) {
        let message = this.$t('my-account.views.personal-data.toast.error');
        if (response) {
          const errors = response.data.errors;
          const firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        ToastHelper.dangerMessage(message);
      } finally {
        this.isUploading = false;
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const personalData = await userService.get(this.loggedUser.id);

      this.personalData = {
        ...personalData,
        ddd: personalData.phone ? personalData.phone.substr(0, 3) : '',
        phone: personalData.phone ? personalData.phone.substr(3) : '',
      };
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('my-account.views.personal-data.toast.error'));
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.border-2 {
  border: 2px solid transparent;
}

.border-dashed {
  border-style: dashed;
}

.uploader {
  height: 91px;
  width: 91px;
  cursor: pointer;
}
</style>
