<template>
  <div class="px-4">
    <div class="d-flex flex-column align-items-start mt-4">
      <div>
        {{ $t('my-account.views.plan.credit-card-info.next-charge') }}
        <span class="font-weight-bold">{{ nextChargeFormatted }}</span>
      </div>
      <router-link :to="{ name: 'MyAccountPlanInvoices' }" class="pointer btn py-2 pl-0 text-primary underline--hover">
        {{ $t('my-account.views.plan.credit-card-info.see-recipes') }}
      </router-link>
    </div>
    <hr class="mt-2 mb-3" />
    <div class="d-flex flex-column align-items-start">
      <div class="d-flex justify-content-between align-items-center w-100 mb-2">
        <span>{{ $t('my-account.views.plan.change-cc-modal.credit-charge') }}</span>
        <div class="d-flex align-items-center">
          <hs-icon class="mr-2" icon="credit-card" v-if="!paymentInfo.creditCard.brand" size="24" />
          <img
            v-else
            class="mr-2"
            :src="
              `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${paymentInfo.creditCard.brand}.svg`
            "
            width="40"
          />
          <span>{{ paymentInfo.creditCard.last4Digits || '****' }}</span>
        </div>
      </div>
      <!-- <a role="button" class="pointer btn py-2 pl-0 text-primary" @click="modalVisible = true">
        {{ $t('my-account.views.plan.credit-card-info.change-card') }}
      </a> -->
    </div>
    <ChangeCCModal
      v-if="modalVisible"
      :visible="modalVisible"
      :payment-info="paymentInfo"
      @close="modalVisible = false"
    />
  </div>
</template>

<script>
import datesHelper from '@/shared/helpers/dates';
import ChangeCCModal from './ChangeCCModal';

export default {
  components: { ChangeCCModal },
  props: {
    paymentInfo: Object,
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  computed: {
    nextChargeFormatted() {
      return (
        this.paymentInfo &&
        datesHelper.format({
          date: this.paymentInfo.nextCharge,
          format: 'DD MMM YYYY',
        })
      );
    },
  },
};
</script>
