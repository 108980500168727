<template>
  <div
    class="bg-white hs-plan-listing-item d-flex flex-column hs-box-shadow tlw-relative"
    :class="{ 'tlw-self-start': !isOpened }"
  >
    <hs-icon
      class="tlw-absolute tlw-top-5 tlw-right-5"
      variant="light"
      size="20"
      :icon="isOpened ? 'chevron-up' : 'chevron-down'"
    />
    <div class="position-relative">
      <div
        data-test="plan-item"
        class="hs-plan-listing-item__header d-flex justify-content-between tlw-cursor-pointer"
        @click="isOpened = !isOpened"
      >
        <h4 class="px-3 pt-4 tlw-text-2xl">
          {{ $t('my-account.views.plan.title') }} <span class="font-weight-bold">{{ plan.title }}</span>
        </h4>
        <div class="d-flex align-items-end justify-content-end">
          <img :src="plan.icon" width="80" />
        </div>
      </div>
    </div>

    <div data-test="plan-content" v-if="isOpened" class="tlw-flex tlw-flex-col tlw-flex-grow">
      <img :src="planAvatar" :alt="plan.title" />

      <div class="d-flex flex-column justify-content-between h-100">
        <div class="d-flex flex-column">
          <div class="p-3 w-100 flex-column justify-content-between justify-content-lg-center align-items-center">
            <div class="my-4 pricebox hs-text-grey-30 d-flex font-weight-bold justify-content-center">
              <p v-if="typeof plan.price === 'number'" class="tlw-text-xl">
                {{ plan.currency }}
                <span class="tlw-text-4xl">{{ priceWithoutDecimal }}</span>
                ,{{ priceDecimal }} /{{ $t('my-account.views.plan.month') }}
              </p>
              <p v-else class="tlw-text-xl tlw-py-1">
                Personalizado
              </p>
            </div>
            <div class="w-100 d-flex justify-content-center">
              <button
                data-id="action-button"
                class="tlw-py-3 tlw-px-5 tlw-rounded tlw-transition-all tlw-ease-in-out"
                :class="{
                  'tlw-bg-purple-500 tlw-text-white hover:tlw-bg-purple-600': !isDisabled,
                  'tlw-bg-gray-500 tlw-text-gray-800 hover:tlw-bg-gray-600': isDisabled,
                }"
                :disabled="isDisabled"
                @click="onActionClick"
              >
                {{ buttonLabel }}
              </button>
            </div>
          </div>

          <div class="p-3">
            <p class="tlw-text-lg tlw-leading-6">{{ plan.content }}</p>
            <p class="tlw-leading-6 tlw-text-base tlw-mt-10 tlw-pb-2 tlw-border-b-2">
              {{ previousPlanLabel }}
            </p>
          </div>

          <div class="tlw-flex-grow">
            <FeaturesListingItem
              data-test="features"
              v-for="(feature, index) in plan.features.highlight"
              :feature="feature"
              :key="index"
              :is-premium="feature.star"
              class="px-3"
            />
          </div>
        </div>
        <button
          class="tlw-cursor-pointer tlw-py-5 tlw-mt-3 tlw-text-center tlw-text-indigo-600 tlw-underline hover:tlw-text-indigo-800"
          @click="planDetailsModalVisible = true"
        >
          {{ $t('my-account.views.plan.plan-listing.see-details') }}
        </button>
      </div>
    </div>

    <PlanDetailsModal
      :visible="planDetailsModalVisible"
      :plan="plan"
      :show-plan-listing-button="false"
      :is-actual="selected"
      @close="planDetailsModalVisible = false"
      @pick="onAssignPlan"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService } from '@/services';
import PlanDetailsModal from '../../Plan/components/PlanDetailsModal/index';
import FeaturesListingItem from '../../Plan/components/ActualPlanPanel/FeaturesListingItem';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import BlipchatService from '@/services/blipchat';
import Tracking from '@/shared/helpers/tracking';

dayjs.extend(isBetween);

export default {
  components: { PlanDetailsModal, FeaturesListingItem },
  props: {
    downgradable: {
      type: Boolean,
      default: false,
    },
    plan: Object,
    selected: Boolean,
  },
  data() {
    return {
      planDetailsModalVisible: false,
      isOpened: true,
    };
  },
  methods: {
    onActionClick() {
      if (this.actualPlan.callConsultant || this.plan.callConsultant) return this.callConsultant();
      if (this.downgradable) return this.$router.push({ name: 'MyAccountDowngradePlan' });
      this.onAssignPlan();
    },
    onAssignPlan() {
      Tracking.planSelected('planList', this.plan.slug);
      this.planDetailsModalVisible = false;
      this.$emit('assign', this.plan);
    },
    callConsultant() {
      return this.openChatSales();
    },
    openChatSales() {
      analyticsService.track({
        event: 'opened chat sales',
        props: {},
      });
      BlipchatService.open(this.selectedSchool, this.actualPlan);
    },
  },
  computed: {
    ...mapState('plan', ['actualPlan']),
    isDisabled() {
      return this.selected || (this.isFree && !this.downgradable);
    },
    isFree() {
      return this.plan.slug === 'free' || this.plan.slug === 'free_legado';
    },
    priceWithoutDecimal() {
      if (typeof this.plan.price !== 'number') return '';
      const absValue = parseInt(this.plan.price);
      return absValue === 0 ? '00' : absValue;
    },
    priceDecimal() {
      if (typeof this.plan.price !== 'number') return '';
      const decimalValue = parseFloat(this.plan.price) - parseInt(this.plan.price);
      return decimalValue === 0 ? '00' : decimalValue.toFixed(2).slice(-2);
    },
    buttonLabel() {
      if (this.selected) return this.$t('my-account.views.plan.plan-listing.your-plan');
      if (this.downgradable) return this.$t('my-account.views.plan.plan-listing.downgrade-plan');
      return this.$t('my-account.views.plan.plan-listing.pick-plan');
    },
    planAvatar() {
      return require(`@/assets/images/robots/${this.plan.slug}.svg`);
    },
    previousPlanLabel() {
      if (this.plan.features.previousPlan) return `Tudo o que tem no ${this.plan.features.previousPlan}`;
      return `Teste de graça com limitações`;
    },
  },
};
</script>

<style scoped lang="scss">
.hs-plan-listing-item__actual-badge {
  position: absolute;
  bottom: -12px;
  left: 15px;
  border: 1px solid $cherry;
  border-radius: 50px;
  font-size: 14px;
}
.hs-plan-listing-item {
  min-width: 195px;
  max-width: 300px;
  width: 22%;

  @media screen and (min-width: 640px) {
    max-width: 550px;
    width: 45%;
  }

  @media screen and (min-width: 1024px) {
    max-width: 440px;
    width: 25%;
  }
}
.hs-plan-listing-item__header {
  min-height: 90px;
}
</style>
