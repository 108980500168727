<template>
  <div id="PaymentFailPanel" class="d-flex flex-column h-100 bg-white">
    <div class="d-flex flex-column justify-content-between h-100" style="min-height:525px">
      <div class="d-flex flex-column justify-content-center align-items-center mt-5 px-5">
        <hs-icon icon="exclamation-circle" :size="50" class="text-primary" />
        <span class="font-weight-bold text-center mt-5">
          {{ $t('my-account.views.plan.payment-abort-panel.title', [plan.title]) }}
        </span>
        <span class="text-center mt-3">
          {{ $t('my-account.views.plan.payment-abort-panel.subtitle') }}
        </span>
      </div>
      <div class="p-4">
        <hs-button variant="primary" class="w-100" @click="onAbort">
          {{ $t('my-account.views.plan.payment-abort-panel.confirm') }}
        </hs-button>
        <hs-button variant="outline-primary" class="w-100 mt-3" @click="onBack">
          {{ $t('my-account.views.plan.payment-abort-panel.cancel') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { analyticsService } from '@/services';

export default {
  props: {
    plan: Object,
  },
  methods: {
    onAbort() {
      analyticsService.track({
        event: 'Upgrade Abandoned',
        props: {
          plan: this.plan.segmentSlug,
          school_id: this.selectedSchool.id,
        },
      });
      this.$emit('cancel');
    },
    onBack() {
      this.$emit('back');
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
};
</script>
