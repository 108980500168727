<template>
  <div id="SignatureChangePanel" class="hs-signature-panel__container d-flex flex-column">
    <div class="hs-bg-light-blue p-4 font-weight-bold">
      <h4>{{ $t('my-account.views.plan.signature-panel.title') }}</h4>
    </div>
    <div class="bg-white p-4">
      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <div class="d-flex flex-column">
          <span class="text-grey"> {{ $t('my-account.views.plan.signature-change-panel.comming-from') }} </span>
          <span class="font-weight-bold text-cherry line-through"
            >{{ $t('my-account.views.plan.title') }} {{ actual.title }}
          </span>
        </div>
        <div class="d-flex flex-lg-column align-items-end">
          <span class="font-weight-bold mr-2 mr-lg-0">{{ actualPrice }}</span>
          <span>{{ $t('my-account.views.plan.signature-change-panel.per-month') }}</span>
        </div>
      </div>
      <hr />
      <div class="d-flex flex-column flex-lg-row justify-content-between">
        <div class="d-flex flex-column">
          <span class="text-grey">
            {{ $t('my-account.views.plan.signature-change-panel.going-to') }}
          </span>
          <span class="font-weight-bold">{{ $t('my-account.views.plan.title') }} {{ destination.title }} </span>
        </div>
        <div class="d-flex flex-lg-column align-items-end">
          <span class="font-weight-bold mr-2 mr-lg-0">{{ destinationPrice }}</span>
          <span>{{ $t('my-account.views.plan.signature-change-panel.per-month') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralHelper from '@/shared/helpers/general';

export default {
  props: {
    actual: Object,
    destination: Object,
  },

  computed: {
    actualPrice() {
      return GeneralHelper.currency(this.actual.price / 100);
    },
    destinationPrice() {
      return GeneralHelper.currency(this.destination.price / 100);
    },
  },
};
</script>
