<template>
  <div class="content-modal d-flex flex-column">
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  props: {
    invoice: Object,
  },
  computed: {
    url() {
      return process.env.VUE_APP_HEIMDALL_BASE_URL + this.invoice.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  iframe {
    height: 70vh;
  }
}
</style>
