<template>
  <div class="d-flex align-items-center mt-3">
    <div
      v-if="feature.icon"
      class="hs-plan__feature-icon bg-info-light rounded-circle mr-2 d-flex justify-content-center align-items-center"
    >
      <hs-icon :icon="feature.icon" class="text-primary"></hs-icon>
    </div>
    <hs-icon
      v-else-if="isPremium"
      :icon="'star'"
      variant="solid"
      class="hs-text-yellow-light-3 mr-2"
      size="12"
    ></hs-icon>
    <div v-else class="d-flex mr-2 justify-content-center" style="width:15px">
      <span class="hs-plan-topic bg-pale-cyan rounded-circle" />
    </div>
    <p class="tlw-text-base w-100 mb-0">
      {{ feature.title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    feature: Object,
    isPremium: Boolean,
  },
};
</script>

<style>
.hs-plan__feature-icon {
  height: 32px;
  width: 32px;
  min-width: 32px;
}
</style>
