<template>
  <section class="tlw-p-5 tlw-container">
    <h3>
      {{ $t('my-account.views.plan.faq.label') }}
    </h3>
    <main class="tlw-grid tlw-grid-cols-1 md:tlw-grid-cols-2 tlw-gap-5 tlw-py-10 tlw-items-start">
      <PlansFrequentAsksItem
        data-test="faq-items"
        v-for="question in questions"
        :key="question.index"
        :question="question"
      />
    </main>
  </section>
</template>

<script>
import PlansFrequentAsksItem from './PlansFrequentAsksItem.vue';
import questions from '@/services/heimdall/plansFaq';

export default {
  name: 'PlansFrequentAsks',
  components: {
    PlansFrequentAsksItem,
  },
  data() {
    return {
      questions,
    };
  },
};
</script>
