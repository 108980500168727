<template>
  <div id="ActualPlanPanel" class="d-flex flex-column bg-white justify-content-between rounded-lg overflow-hidden">
    <div class="hs-free-plan-card__header d-flex flex-column px-4 pt-4">
      <h4>
        {{ $t('my-account.views.plan.actual-plan-panel.title') }}
        <span class="font-weight-bold">
          {{ plan.title }}
        </span>
      </h4>
      <div>
        <router-link :to="{ name: 'MyAccountPlans' }" class="pointer btn pb-3 pr-4 pl-0 text-primary">
          {{ $t('my-account.views.plan.actual-plan-panel.change-plan') }}
        </router-link>
      </div>
    </div>
    <component :is="planPanel" />
    <a
      role="button"
      class="hs-bg-light-blue pointer btn pb-2 px-2 text-primary bg-pale-cyan--hover"
      @click="openPlanDetailsModal"
      v-if="plan.groupsFeatures"
    >
      {{ $t('my-account.views.plan.see-all-features') }}
    </a>
    <PlanDetailsModal
      :visible="planDetailsModalVisible"
      @close="planDetailsModalVisible = false"
      :plan="plan"
      is-actual
    />
  </div>
</template>

<script>
import PlusPlanPanel from './PlusPlanPanel';
import BasicPlanPanel from './BasicPlanPanel';
import NewFreePlanPanel from './NewFreePlanPanel';
import AdvancedPlanPanel from './AdvancedPlanPanel';
import ProPerpetualPlanPanel from './ProPerpetualPlanPanel';
import FreePlanPanel from './FreePlanPanel';
import PlanDetailsModal from '../PlanDetailsModal/index';
import ProPlanPanel from './ProPlanPanel';

const planComponents = {
  new_free: NewFreePlanPanel,
  basic_pro: BasicPlanPanel,
  advanced_hero: AdvancedPlanPanel,
  plus_hero: PlusPlanPanel,
  free: FreePlanPanel,
  pro: ProPlanPanel,
  heroPerpetual: ProPerpetualPlanPanel,
};

export default {
  props: {
    plan: Object,
  },
  components: {
    BasicPlanPanel,
    PlusPlanPanel,
    AdvancedPlanPanel,
    ProPerpetualPlanPanel,
    NewFreePlanPanel,
    FreePlanPanel,
    PlanDetailsModal,
    ProPlanPanel,
  },
  data() {
    return {
      planDetailsModalVisible: false,
    };
  },
  computed: {
    planPanel() {
      return planComponents[this.plan.slug];
    },
  },
  methods: {
    openPlanDetailsModal() {
      this.planDetailsModalVisible = true;
    },
  },
};
</script>

<style lang="scss">
.hs-free-plan-card__header {
  background: $grey-17;
}
.hs-free-plan-card__footer {
  background: $light-blue;
}
</style>
