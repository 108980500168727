<template>
  <div id="SignaturePanel" class="hs-signature-panel__container d-flex flex-column bg-white">
    <div class="hs-signature-panel__header p-4 font-weight-bold">
      <h4>{{ $t('my-account.views.plan.signature-panel.title') }}</h4>
    </div>
    <CreditCardInfo v-if="paymentInfo" :payment-info="paymentInfo" />
    <div v-if="paymentInfo && order.canceled_at" class="px-4">
      <hr class="mt-2 mb-3" />
    </div>
    <div v-if="order.canceled_at && order.expires_at" class="tlw-flex tlw-items-center tlw-justify-between mt-4 px-4">
      <div>
        <hs-icon :icon="'star'" variant="solid" class="hs-text-yellow-light-3 mr-2" size="12"></hs-icon>
        <span>
          {{ $t('my-account.views.plan.credit-card-info.until') }}
          <span class="font-weight-bold">{{ untilFormatted }}</span></span
        >
      </div>
    </div>
    <hr v-if="paymentInfo" class="hs-signature-panel__divider mx-4 mt-2 mb-0" />
    <div class="p-4">
      <div class="d-flex justify-content-between">
        <span class="d-flex align-items-end">
          {{ $t('my-account.views.plan.signature-panel.plan') }} {{ plan.title }}
        </span>
        <div class="d-flex flex-column align-items-end" v-if="plan.price !== undefined">
          <span>{{ $t('my-account.views.plan.signature-panel.month-value') }}</span>
          <span>{{ price }}</span>
        </div>
      </div>
      <hr class="hs-signature-panel__divider my-4" />
      <div class="d-flex justify-content-between font-weight-bold">
        <span>{{ $t('my-account.views.plan.signature-panel.you-pay') }}</span>
        <div class="d-flex align-items-start">
          <span>{{ price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import planService from '@/services/heimdall/newPlans';
import CreditCardInfo from './CreditCardInfo';
import { mapState } from 'vuex';
import GeneralHelper from '@/shared/helpers/general';
import dayjs from 'dayjs';

export default {
  components: {
    CreditCardInfo,
  },
  props: {
    plan: Object,
  },
  data() {
    return {
      paymentInfo: null,
      order: {},
    };
  },
  async mounted() {
    this.paymentInfo = this.plan.price && (await planService.getPaymentInfo());
    await this.getPlanOrder();
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    untilFormatted() {
      return this.order && dayjs(this.order.expires_at).format('DD MMM YYYY');
    },
    price() {
      return this.plan.price ? GeneralHelper.currency(this.plan.price / 100) : null;
    },
    totalPrice() {
      const price = this.plan.price;
      const months = this.plan.months_duration || 1;
      return price ? GeneralHelper.currency((price * months) / 100) : null;
    },
  },
  methods: {
    async getPlanOrder() {
      const orders = await planService.getOrders();
      this.order = orders[0];
    },
  },
};
</script>

<style lang="scss">
.hs-signature-panel__container {
  border-radius: 4px;
  overflow: hidden;
}
.hs-signature-panel__header {
  background: $light-blue;
}
.hs-signature-panel__currency {
  font-size: 10px;
}
.hs-signature-panel__divider {
  border-color: #868686;
}
</style>
