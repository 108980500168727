<template>
  <div>
    <div class="d-none d-lg-block">
      <b-modal
        id="ChangeCCModal"
        :visible="visible"
        :static="true"
        size="lg"
        centered
        hide-footer
        header-class="hs-change-cc-modal__header align-items-center border-0 hs-bg-grey-10 px-3 px-lg-5"
        body-class="pt-0 px-0"
        @hidden="onClose"
        :title="$t('my-account.views.plan.change-cc-modal.change-payment-data')"
      >
        <ChangeCCModalContent :payment-info="paymentInfo" class="px-3 px-lg-5" />
      </b-modal>
    </div>
    <div
      class="hs-change-cc-modal__mobile-wrapper d-block d-lg-none position-fixed w-100 h-100 bg-white"
      v-if="visible"
    >
      <div class="hs-bg-grey-10 p-4 d-flex justify-content-between align-items-center">
        <h4 class="font-weight-bold">
          {{ $t('my-account.views.plan.change-cc-modal.change-payment-data') }}
        </h4>
        <span class="pointer hs-text-36 hs-lh-0" @click="onClose">×</span>
      </div>
      <ChangeCCModalContent class="hs-change-cc-modal__content px-3 px-lg-5" :payment-info="paymentInfo" />
    </div>
  </div>
</template>

<script>
import ChangeCCModalContent from './ChangeCCModalContent';
export default {
  components: { ChangeCCModalContent },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    paymentInfo: Object,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.hs-change-cc-modal__header {
  .close {
    font-size: 35px;
  }
  .modal-title {
    font-size: 1.875rem;
  }
}
.hs-change-cc-modal__mobile-wrapper {
  top: 0px;
  left: 0px;
  z-index: 2;
}
.hs-change-cc-modal__content {
  height: calc(100% - 90px);
}
@media only screen and (min-width: $screen-lg) {
  #ChangeCCModal {
    .modal-lg {
      max-width: 600px;
    }
  }
}
</style>
