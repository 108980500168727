<template>
  <div :id="`card-container-${id}`"></div>
</template>

<script>
import AdyenCheckout from '@adyen/adyen-web';
import sparkPayService from '@/sparkpay/services/sparkpay';
import '@adyen/adyen-web/dist/adyen.css';

export default {
  data() {
    return {
      paymentMethod: {},
      id:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    };
  },
  async mounted() {
    const originKey = await sparkPayService.fetchOriginKey();
    this.createCard(originKey);
  },
  methods: {
    onChangeCard(state) {
      this.paymentMethod = {
        ...this.paymentMethod,
        ...state.data.paymentMethod,
      };
      this.$emit('change', this.paymentMethod);
    },
    onChangeBrand(data) {
      this.paymentMethod = {
        ...this.paymentMethod,
        brand: data.brand,
      };
      this.$emit('change', this.paymentMethod);
    },
    createCard(originKey) {
      const configuration = {
        locale: 'pt_BR',
        environment: process.env.VUE_APP_SPARKPAY_ENV,
        originKey,
        onChange: this.onChangeCard,
        onBrand: this.onChangeBrand,
        onLoad: () => {
          this.$emit('load');
        },
      };
      const checkout = new AdyenCheckout(configuration);
      const config = {
        hasHolderName: true,
        holderNameRequired: true,
        brands: ['mc', 'visa', 'hipercard', 'elo', 'amex'],
        placeholders: {
          encryptedCardNumber: '0000 0000 0000 0000',
          encryptedExpiryDate: 'MM/AA',
          encryptedSecurityCode: '123',
          holderName: 'João A. Silva',
        },
      };
      checkout.create('card', config).mount(`#card-container-${this.id}`);
    },
  },
};
</script>

<style lang="scss">
.adyen-checkout__input {
  border-radius: 2px !important;
}
.adyen-checkout__input:focus,
.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover {
  border: 1px solid $cyan !important;
  box-shadow: none !important;
  caret-color: black;
}
.adyen-checkout__icon {
  margin-bottom: 6px;
}
.adyen-checkout__label__text {
  color: $grey-25 !important;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0px;
}
</style>
