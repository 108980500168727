<template>
  <section v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </section>
  <div class="container-fluid p-0" v-else>
    <div class="row no-gutters py-3 py-xl-4 px-4 px-xl-5 bg-white align-items-center">
      <div class="col">
        <router-link :to="{ name: 'MyAccountDashboard' }" class="text-dark font-size-sm text-decoration-none">
          <hs-icon icon="long-arrow-left" /> {{ $t('my-account.views.dashboard.title') }}
        </router-link>
        <h3 class="font-weight-bold">{{ $t('breadcrumb.MyAccountDomains') }}</h3>
      </div>
    </div>

    <div class="my-3 my-lg-4 my-xl-5 mx-3 mx-lg-4 mx-xl-5">
      <div class="row">
        <div class="col-lg-8">
          <div class="bg-white rounded-lg shadow-sm p-4 h-100">
            <h4 class="font-weight-bold mb-3">
              {{ $t('my-account.views.domains.index.new-domain-column.title') }}
            </h4>
            <p class="tlw-mb-2" v-html="$t('my-account.views.domains.index.new-domain-column.subtitle')"></p>
            <p class="tlw-mb-2" v-html="$t('my-account.views.domains.index.new-domain-column.subtitle-second')"></p>
            <hs-button
              id="addDomain"
              variant="outline-primary"
              class="custom-button tlw-relative"
              @click="addCustomDomain"
            >
              {{ $t('my-account.views.domains.index.new-domain-column.button-text') }}
              <hs-icon
                icon="star"
                size="16px"
                variant="solid"
                class="premium-badge tlw-ml-1 tlw-p-1 rounded-circle tlw-absolute"
              />
            </hs-button>
          </div>
        </div>
        <div class="col-lg-4 mt-4 mt-lg-0">
          <div class="bg-white rounded-lg shadow-sm p-4 h-100">
            <h4 class="font-weight-bold mb-4">
              {{ $t('my-account.views.domains.index.herospark-domain-column.title') }}
            </h4>
            <div class="d-flex mb-4">
              <div class="w-50">
                <hs-input
                  :placeholder="
                    $t('my-account.views.domains.index.herospark-domain-column.inputs.subdomain.placeholder')
                  "
                  :text="$t('my-account.views.domains.index.herospark-domain-column.inputs.subdomain.text')"
                  v-model="subdomain"
                  :state="!$v.subdomain.$error ? null : false"
                  @blur="$v.subdomain.$touch()"
                />
              </div>
              <div class="w-50 h-100 overflow-hidden">
                <p class="text-secondary border rounded-lg px-2 pt-2 pb-1 mb-0">
                  {{ mainDomain }}
                </p>
                <p class="font-size-xs text-secondary mt-1 mb-0">
                  {{ $t('my-account.views.domains.index.herospark-domain-column.inputs.domain.text') }}
                </p>
              </div>
            </div>

            <m-button
              data-testid="change-domain"
              variant="primary"
              class="tlw-mb-1"
              :class="{ 'tlw-pointer-events-none': buttonDisable }"
              @click="onChangeDomain"
              :disabled="buttonDisable"
            >
              {{ $t('my-account.views.domains.index.herospark-domain-column.button-text') }}
            </m-button>
          </div>
        </div>
      </div>

      <div class="row mt-0 mt-lg-3">
        <div class="col-lg-12 mt-4 mt-lg-0">
          <h4 class="font-weight-bold mb-3">
            {{ $t('my-account.views.domains.index.connected-domains.title') }}
          </h4>
          <div v-for="domain in allDomains" :key="domain.url" class="mb-4 mb-lg-3">
            <div class="d-flex flex-column flex-md-row justify-content-between bg-white shadow-sm rounded-lg p-2">
              <div class="p-1 p-lg-2" @click="openDetailsDomain(domain)">
                <hs-icon variant="light" icon="globe" class="text-primary ml-3" :size="16" />
                <p class="d-inline font-weight-bold ml-3">{{ domain.url }}</p>
              </div>
              <div class="d-flex align-items-center p-1 p-lg-2">
                <hs-badge pill :variant="domain.status ? 'success' : 'danger'" class="mx-3">{{
                  $t(
                    `my-account.views.domains.index.connected-domains.status.${
                      domain.status ? 'connected' : 'problem-detected'
                    }`
                  )
                }}</hs-badge>
                <hs-button
                  v-if="domains.length >= 1"
                  icon="trash-alt"
                  variant="light"
                  class="p-0 m-0 bg-transparent"
                  @click="onDeleteDomain(domain)"
                  data-testid="delete-domain"
                />
              </div>
            </div>
            <b-collapse v-if="!domain.status" v-model="domain.visible">
              <div class="bg-secondary-light p-4">
                <p class="font-weight-bold font-size-sm">
                  {{ $t('my-account.views.domains.index.connected-domains.problem.title') }}
                </p>
                <p class="font-weight-bold font-size-sm mb-4">
                  {{ $t('my-account.views.domains.index.connected-domains.problem.subtitle') }}
                </p>

                <p
                  class="font-size-sm mb-4"
                  v-html="$t('my-account.views.domains.index.connected-domains.problem.stages.1')"
                ></p>
                <p
                  class="font-size-sm mb-4"
                  v-html="$t('my-account.views.domains.index.connected-domains.problem.stages.2', { target: cname })"
                ></p>
                <p
                  class="font-size-sm mb-4"
                  v-html="$t('my-account.views.domains.index.connected-domains.problem.stages.3')"
                ></p>
                <p
                  class="font-size-sm mb-4"
                  v-html="$t('my-account.views.domains.index.connected-domains.problem.stages.4')"
                ></p>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <AddDomainModal @created-domain="handleAddNewDomain" />
    <hsFunnelsPremiumModal id="premium-modal-id" @click="redirectToSalesPage" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import AddDomainModal from './components/AddDomainModal.vue';
import OkMessage from '@/shared/mixins/OkMessage';
import Confirm from '@/shared/mixins/Confirm';
import ToastHelper from '@/shared/helpers/toast';
import { customerService, subdomainService, schoolApi } from '@/services';
import { hsLoading } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import hsFunnelsPremiumModal from '@/sparkfunnels/components/FunnelsPremiumModal';
import TrackingHelper from '@/shared/helpers/tracking';
import { LocationSegmentProps, PaywallReasonSegmentProps } from '@/libs/paywall';
import GeneralHelper from '@/shared/helpers/general';

export default {
  name: 'MyAccountDomains',
  mixins: [OkMessage, Confirm],
  data() {
    return {
      domains: [],
      subdomain: '',
      isLoading: false,
      buttonDisable: true,
      intervalId: null,
      timeoutId: null,
    };
  },
  components: {
    AddDomainModal,
    hsLoading,
    MButton,
    hsFunnelsPremiumModal,
  },
  validations: {
    subdomain: {
      required,
    },
  },
  watch: {
    domains(newVal, old) {
      if (newVal.every(d => d.status === true)) {
        this.stopPolling();
      }
    },
    subdomain(newValue, oldValue) {
      if (oldValue !== '') {
        this.buttonDisable = false;
      }
    },
  },
  computed: {
    ...mapGetters('school', ['getDefaultDomain']),
    ...mapState('school', ['selectedSchool']),
    heroDomain() {
      return [
        {
          main: true,
          url: GeneralHelper.accountDomain({
            accountType: this.selectedSchool.account_type,
            subDomain: this.selectedSchool.subdomain,
          }),
          visible: false,
          status: true,
        },
      ];
    },
    allDomains() {
      return [...this.heroDomain, ...this.domains];
    },
    mainDomain() {
      return this.selectedSchool.account_type === 'herospark'
        ? process.env.VUE_APP_HERO_DOMAIN
        : process.env.VUE_APP_DOMAIN_URL;
    },
    cname() {
      return `cname.${process.env.VUE_APP_DOMAIN_URL}`;
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    openDetailsDomain(domain) {
      domain.visible = !domain.visible;
      this.$forceUpdate();
    },

    startPolling() {
      this.stopPolling();

      this.intervalId = setInterval(() => {
        this.loadDomains();
      }, 5000);

      this.timeoutId = setTimeout(() => {
        this.stopPolling();
      }, 10 * 60 * 1000);
    },
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },

    async addCustomDomain() {
      if (this.$FCL.canUseCustomDomain()) {
        this.$bvModal.show('add-domain-name-modal');
      } else {
        this.$bvModal.show('premium-modal-id');
        this.trackAnalytics();
      }
    },
    redirectToSalesPage() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal-id');
    },
    async trackAnalytics() {
      TrackingHelper.trackPaywallDisplayed(
        LocationSegmentProps.DOMAIN_CUSTOM,
        PaywallReasonSegmentProps.DOMAIN_CUSTOM,
        this.selectedSchool.email,
        this.selectedSchool.id,
        this.selectedSchool.id
      );
    },
    async onDeleteDomain(domain) {
      try {
        const domainIndex = this.domains.findIndex(dom => dom.url === domain.url);

        if (domainIndex === -1) {
          this.showOkMessageBox({
            icon: { name: 'trash-alt', color: 'cherry' },
            hideFooter: true,
            size: 'smd',
            contentTitle: this.$t('my-account.views.domains.index.connected-domains.ok-domain-box.title'),
            contentDescription: this.$t('my-account.views.domains.index.connected-domains.ok-domain-box.description'),
          });
          return;
        }

        const confirmed = await this.showConfirmBox({
          okTitle: this.$t('my-account.views.domains.index.connected-domains.delete-domain-box.ok'),
          cancelTitle: this.$t('my-account.views.domains.index.connected-domains.delete-domain-box.cancel'),
          contentTitle: this.$t('my-account.views.domains.index.connected-domains.delete-domain-box.title'),
          contentDescription: this.$t('my-account.views.domains.index.connected-domains.delete-domain-box.description'),
          variant: 'cherry',
          icon: 'trash-alt',
        });

        if (confirmed) {
          ToastHelper.warningMessage(this.$t('my-account.views.domains.index.toast.warning'));
          const domains = [...this.domains];

          const deletedDomain = domains.splice(domainIndex, 1);

          await schoolApi.deleteDomain({
            schoolId: this.selectedSchool.id,
            domain: deletedDomain[0].url,
          });

          await this.loadDomains();

          ToastHelper.successMessage(this.$t('my-account.views.domains.index.toast.success-remove'));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('my-account.views.domains.index.toast.error-remove'));
      }
    },
    validateSubdomain(subdomain) {
      return subdomainService.subdomainValidation(subdomain);
    },
    async onChangeDomain() {
      try {
        const confirmed = await this.showConfirmBox({
          okTitle: this.$t('my-account.views.domains.index.connected-domains.change-domain-box.ok'),
          cancelTitle: this.$t('my-account.views.domains.index.connected-domains.change-domain-box.cancel'),
          contentTitle: this.$t('my-account.views.domains.index.connected-domains.change-domain-box.title'),
          contentDescription: this.$t('my-account.views.domains.index.connected-domains.change-domain-box.description'),
        });

        if (confirmed) {
          if (await this.validateSubdomain(this.subdomain)) {
            await this.updateSchool({
              id: this.selectedSchool.id,
              domains: this.selectedSchool.domains,
              subdomain: this.subdomain,
            });

            if (this.selectedSchool.uid) await customerService.updateSchool(this.selectedSchool);

            ToastHelper.successMessage(this.$t('my-account.views.domains.index.toast.success-change'));

            TrackingHelper.trackSubdomainEdited();
          }
        }
      } catch (e) {
        const { response } = e;
        ToastHelper.dangerMessage(this.$t(response.data.error));
      }
    },
    formatNewResponseDomain(domain) {
      return {
        main: false,
        url: domain.domain,
        visible: false,
        status: domain.ssl.status === 'issued',
      };
    },
    async handleAddNewDomain() {
      await this.loadDomains();
      this.startPolling();
    },
    async loadDomains() {
      const res = await schoolApi.listDomains(this.selectedSchool.id);
      const domains = res.data.map(this.formatNewResponseDomain);
      this.domains = [...domains];
    },
  },
  beforeDestroy() {
    this.stopPolling();
  },
  async mounted() {
    try {
      this.isLoading = true;
      this.subdomain = this.selectedSchool.subdomain;

      await this.loadDomains();
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('my-account.views.domains.index.toast.error-load'));
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';
.bg-secondary-light {
  background-color: $grey-17;
}

.premium-badge {
  color: $yellow-dark;
  background: $yellow-light-02;
  top: -15px;
  right: 5px;
}
</style>
