<template>
  <section v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </section>
  <div class="container-fluid p-0" v-else>
    <div class="row no-gutters py-3 py-lg-4 px-4 px-lg-5 bg-white align-items-center">
      <div class="col">
        <router-link :to="{ name: 'MyAccountDashboard' }" class="text-dark font-size-sm text-decoration-none">
          <hs-icon icon="long-arrow-left" /> {{ $t('my-account.views.dashboard.title') }}
        </router-link>
        <h3 class="font-weight-bold">{{ $t('breadcrumb.MyAccountSettings') }}</h3>
      </div>
    </div>
    <div class="row my-3 mx-1 my-lg-5 mx-lg-4">
      <div class="col-12 col-lg-8">
        <div class="bg-white rounded-lg shadow-sm p-4">
          <div class="row">
            <div class="col-lg-12">
              <hs-group
                :label="$t('my-account.views.account-settings.inputs.name.label')"
                label-for="name"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <hs-input
                  id="name"
                  :placeholder="$t('my-account.views.account-settings.inputs.name.placeholder')"
                  v-model="school.name"
                  :state="!$v.school.name.$error ? null : false"
                  @blur="$v.school.name.$touch()"
                />
              </hs-group>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-lg-12">
              <label class="font-weight-bold mb-0">{{
                $t('my-account.views.account-settings.inputs.logo.label')
              }}</label>
              <p class="font-size-xs">{{ $t('my-account.views.account-settings.inputs.logo.placeholder') }}</p>
              <div class="d-flex flex-wrap mt-1">
                <div class="position-relative mr-2">
                  <div class="position-absolute w-100 text-right">
                    <hs-icon class="text-purple mr-2" variant="solid" icon="check-circle" />
                  </div>
                  <b-img-lazy
                    height="91"
                    width="91"
                    class="rounded-lg border-2"
                    :src="school.logo ? school.logo : ''"
                  />
                </div>
                <label
                  for="img-input"
                  class="d-flex flex-column justify-content-center rounded-lg border-2 border-dashed uploader"
                  @dragover.prevent
                  @drop.prevent="onFile"
                >
                  <div class="text-center" v-if="isUploading">
                    <b-spinner small type="grow" variant="primary" />
                  </div>
                  <template v-else>
                    <div class="text-center">
                      <p class="font-size-xs">{{ $t('my-account.views.account-settings.inputs.logo.info') }}</p>
                      <hs-icon class="font-size-lg" variant="light" icon="cloud-upload" />
                    </div>
                  </template>
                  <input id="img-input" type="file" class="d-none" @input="onFile" />
                </label>
              </div>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-lg-12">
              <hs-group
                :label="$t('my-account.views.account-settings.inputs.email.label')"
                label-for="email"
                label-class="font-weight-bold"
                class="mb-2"
              >
                <hs-input
                  id="email"
                  :placeholder="$t('my-account.views.account-settings.inputs.email.placeholder')"
                  v-model="school.email"
                  :text="$t('my-account.views.account-settings.inputs.email.info')"
                  :state="!$v.school.email.$error ? null : false"
                  @blur="$v.school.email.$touch()"
                />
              </hs-group>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <hs-button
              class="rounded-sm"
              id="save-button"
              :variant="$v.school.$invalid || isUploading ? 'light' : 'purple'"
              :disabled="$v.school.$invalid || isUploading"
              @click="onEdit()"
            >
              <b-spinner small type="grow" v-if="isUploading" />
              {{
                isUploading
                  ? $t('my-account.views.account-settings.buttons-text.saving')
                  : $t('my-account.views.account-settings.buttons-text.save')
              }}
            </hs-button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-4 mt-lg-0">
        <BankAccount v-if="hasSparkMembersFeature" />
        <BusinessAddress :address="parsedAddress" :forceRegister="false" :isAdminUser="true" />
        <PhoneValidation
          :userId="userId"
          :phone="phone"
          :ddi="ddi"
          :canChange="selectedSchool.allow_change_phone"
          :phoneValidated="selectedSchool.phone_verified_at || ''"
          @showValidationModal="openValidationModal"
          @hideValidationModal="closeValidationModal"
        />
      </div>
    </div>
    <ValidationModal
      modal-id="phone-validation-modal"
      :userId="userId"
      :phone="phone"
      :ddi="ddi"
      :isSignUp="false"
      :invalidCode="invalidCode"
      @changePhoneNumber="changePhoneNumber"
      @updatePhone="updatePhone"
      @removeWarning="removeWarning"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { analyticsService, uploadService } from '@/services';
import { hsLoading } from '@/components';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import { isValidCNPJ as isValidCNPJInpl, isValidCEP } from '@brazilian-utils/brazilian-utils';
import { helpers } from 'vuelidate/lib/validators';
import { isValidJSON } from '@/shared/helpers/general';
const isValidCNPJ = value => !helpers.req(value) || isValidCNPJInpl(value);
import httpClient from '@/services/sparkecommerce/http_client';

export default {
  name: 'MyAccountSettings',
  components: {
    hsLoading,
    BankAccount: () => import('./components/BankAccount'),
    PhoneValidation: () => import('./components/PhoneValidation'),
    ValidationModal: () => import('./components/ValidationModal'),
    BusinessAddress: () => import('./components/BusinessAddress'),
  },
  validations: {
    school: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      company_name: {
        //
      },
      cnpj: {
        isValidCNPJ,
      },
    },
    cep: {
      maxLength: maxLength(9),
      isValidCEP,
    },
  },
  data() {
    return {
      cep: '',
      isLoading: false,
      isUploading: false,
      gateway: {},
      school: {},
      phone: '',
      ddi: '',
      userId: '',
      invalidCode: false,
    };
  },
  computed: {
    ...mapState('school', {
      selectedSchool: state => state.selectedSchool,
    }),
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
    hasSparkMembersFeature() {
      return this.selectedSchool.features.includes('spark_members');
    },
    parsedAddress() {
      const address = isValidJSON(this.selectedSchool.address) ? JSON.parse(this.selectedSchool.address) : {};
      return typeof address === 'object' ? address : {};
    },
  },

  methods: {
    ...mapActions('school', ['updateSchool', 'getSchoolById', 'setPhoneValidated']),
    async onFile(e) {
      this.isUploading = true;
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.srcElement.files[0];
      try {
        const { data } = await uploadService.getAwsKeys(file.type, this.selectedSchool.id);
        this.school.logo = await uploadService.upload(data.url, data.fields, file);
      } catch {
        ToastHelper.dangerMessage(this.$t('my-account.views.account-settings.toast.error'));
      } finally {
        this.isUploading = false;
      }
    },
    async onEdit(data = null) {
      try {
        this.isUploading = true;
        const school = { ...this.school };
        school.phone = data ? data.phone : school.phone;
        school.cnpj = school.cnpj ? school.cnpj.replace(/\D+/g, '') : '';
        school.sms_token = data ? data.smsToken : null;
        school.sms_token_id = data ? data.smsTokenId : null;
        school.ddi = data ? data.ddi : school.phone;

        if (!data) {
          delete school.phone;
          delete school.ddi;
        }

        await httpClient.patch(`organizations/${school.id}`, {
          email: school.email,
          logo_url: school.logo,
          document_type: school.cnpj ? 'cnpj' : 'cpf',
          document_id: school.cnpj ? school.cnpj : school.cpf,
          name: school.name,
        });

        const response = await this.updateSchool(school);

        if (!response) {
          ToastHelper.successMessage(this.$t('my-account.views.account-settings.toast.success'));
          this.closeValidationModal();
          this.setPhoneValidated();
          analyticsService.track({
            event: 'Settings Phone Validated',
            props: {
              name: school.name,
              phone: school.phone,
              email: school.email,
            },
          });
        }
      } catch (error) {
        if (error.response?.status == 401) {
          ToastHelper.dangerMessage(this.$t(`my-account.views.account-settings.toast.unauthorized`));
          this.invalidCode = true;
          analyticsService.track({
            event: 'Settings Phone Code Error',
            props: {
              name: this.school.name,
              phone: this.school.ddd + this.school.phone,
              email: this.school.email,
            },
          });
        } else {
          ToastHelper.dangerMessage(this.$t('my-account.views.account-settings.toast.error'));
        }
      } finally {
        this.isUploading = false;
      }
    },
    cepKeyUp() {
      if (this.cep.length == 5) {
        this.cep = this.cep.substr(0, 5) + '-';
      }
    },
    openValidationModal(data) {
      this.phone = data.phone;
      this.userId = data.userId;
      this.ddi = data.ddi;
      this.openModal('phone-validation-modal');
    },
    closeValidationModal() {
      this.closeModal('phone-validation-modal');
    },
    changePhoneNumber(data) {
      this.$root.$emit('changePhoneValidation', data);
    },
    updatePhone(data) {
      this.onEdit(data);
    },
    removeWarning() {
      this.invalidCode = false;
    },
    openModal(id) {
      this.$bvModal.show(id);
    },
    closeModal(id) {
      this.$bvModal.hide(id);
    },
  },
  async created() {
    const school = { ...this.selectedSchool };
    const phone = school.phone ? school.phone.replace(/[\s\-/{()}]+/g, '') : '';
    const address = school.address ? school.address : '';
    const ddi = school.ddi;
    this.school = { ...school, address, phone, ddi };
    this.phone = phone;
    this.ddi = ddi;
    this.userId = school.subdomain;
  },
};
</script>
<style lang="scss" scoped>
.border-2 {
  border: 2px solid #7427f1;
}

.border-dashed {
  border-style: dashed;
}

.uploader {
  cursor: pointer;
  width: 91px;
  height: 91px;
}
</style>
