<template>
  <div class="d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
      <div class="my-3">
        <span v-html="plan.content" />
      </div>
      <div class="hs-plan-details__body">
        <div class="hs-plan-details__subproducts-wrapper d-flex flex-column p-1">
          <div
            v-for="(groupFeature, index) in groupsFeatures"
            :key="groupFeature.title"
            class="card rounded"
            :class="index !== 0 ? 'mt-2' : ''"
          >
            <div
              :aria-expanded="groupFeature.star ? 'true' : 'false'"
              :aria-controls="'acordion-' + index"
              class="p-3 hs-bg-light-blue d-flex justify-content-between align-items-center pointer"
              @click="groupFeature.open = !groupFeature.open"
            >
              <h4 class="font-weight-bold">
                {{ groupFeature.title }}
                <hs-icon
                  v-if="groupFeature.star"
                  class="hs-text-yellow-light-3 mr-2"
                  icon="star"
                  size="12"
                  variant="solid"
                />
              </h4>
              <hs-icon variant="light" size="20" :icon="!groupFeature.open ? 'chevron-down' : 'chevron-up'" />
            </div>
            <b-collapse v-model="groupFeature.open">
              <div class="d-flex flex-column p-3">
                <div class="d-flex flex-column">
                  <div
                    v-for="(feature, key) in orderedFeatures(groupFeature.features)"
                    :key="key"
                    class="d-flex align-items-center mb-2"
                  >
                    <p v-if="feature.value">
                      <hs-icon
                        v-if="feature.star"
                        :icon="'star'"
                        variant="solid"
                        class="hs-text-yellow-light-3 mr-2"
                        size="12"
                      ></hs-icon>
                      <span :class="{ 'tlw-text-blue-600': !feature.ready }">
                        {{ featureLabel(feature) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="hs-plan-details__footer d-flex flex-column flex-lg-row justify-content-between w-100 mt-4 px-3 px-lg-0">
      <div class="">
        <hs-button
          v-if="showPlanListingButton && !showPlanPickButton"
          variant="outline-primary"
          class="w-100 w-lg-auto"
          @click="onSeePlansClick"
          :to="{ name: 'MyAccountPlans' }"
        >
          {{ $t('my-account.views.plan.plan-details.see-other') }}
        </hs-button>
        <hs-button
          v-if="showPlanPickButton && plan.slug === 'pro'"
          variant="outline-primary"
          class="w-100 w-lg-auto"
          @click="onChoosePlan"
        >
          {{ $t('my-account.views.plan.next-plan-banner.choose-plan') }}
        </hs-button>
      </div>
      <hs-button variant="primary" @click="$emit('close')" class="mt-2 mt-lg-0">{{
        $t('my-account.views.plan.plan-details.ok')
      }}</hs-button>
    </div>
  </div>
</template>

<script>
import planService from '@/services/heimdall/newPlans';
import { analyticsService } from '@/services';
import Tracking from '@/shared/helpers/tracking';

export default {
  props: {
    plan: Object,
    showPlanListingButton: {
      type: Boolean,
      default: true,
    },
    showPlanPickButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      groupsFeatures:
        this.plan.features?.groups &&
        this.plan.features?.groups.map((group, index) => ({ ...group, open: index === 0 })),
    };
  },
  methods: {
    featureLabel(feature) {
      let label = feature.title;
      if (!feature.ready) label += `: ${this.$t('my-account.views.plan.plan-details.coming-soon')}`;
      else if (typeof feature.value !== 'boolean') label += `: ${feature.value}`;
      return label;
    },
    onSeePlansClick() {
      this.$emit('close');
      this.$router.push({ name: 'MyAccountPlans' });
    },
    onChoosePlan() {
      this.$emit('pick');
      Tracking.planSelected('moreInfo', this.plan.segmentSlug);
    },
    orderedFeatures(features) {
      features = features.filter(feat => !!feat.value);
      features = features.sort(a => {
        return a.star ? -1 : 1;
      });
      return features;
    },
  },
  computed: {
    isProPlan(plan) {
      return planService.isProPlan(plan);
    },
    isFreePlan(plan) {
      return planService.isFreePlan(plan);
    },
  },
};
</script>

<style lang="scss">
.hs-plan-details__subproducts-wrapper {
  height: calc(100vh - 282px);
  overflow-y: auto;
  border: 1px solid #e9e9e9;
}
.hs-plan-details__subproducts-wrapper > div {
  border: 1px solid $light-blue;
}
.hs-plan-details__footer {
  position: fixed;
  bottom: 10px;
  left: 0px;
  button {
    width: 100%;
  }
}
@media screen and (min-width: $screen-md) {
  .hs-plan-details__footer {
    position: relative;
    button {
      width: 180px;
    }
  }
  .hs-plan-details__subproducts-wrapper {
    height: 500px;
  }
}
.hs-plan-topic-wrapper {
  width: 15px;
}
</style>
