<template>
  <section class="d-flex position-relative flex-fill flex-column">
    <hsPageHeader
      class="d-none d-lg-block"
      :title="$t('my-account.views.plan-invoices.title')"
      :back-text="$t('my-account.views.plan.back')"
      back-route="MyAccountPlan"
    />
    <div v-if="invoices" class="d-flex flex-column flex-lg-row m-lg-2 m-lg-5 pr-lg-5 h-100 h-lg-auto">
      <div class="d-flex d-lg-none bg-white justify-content-between align-items-center font-weight-bold py-3 px-4 mt-1">
        <h3>{{ $t('my-account.views.plan-invoices.title') }}</h3>
        <span id="close-btn" class="pointer hs-text-36 hs-lh-0" @click="onClose">×</span>
      </div>
      <div class="hs-plan-downgrade col-lg-12 p-4 mr-5 rounded h-100 d-flex flex-column">
        <div class="d-flex flex-column">
          <div v-if="actualPlan">
            {{ $t('my-account.views.plan-invoices.actual-plan') }}
            <span class="font-weight-bold">{{ actualPlan.title }}</span>
          </div>
          <div>
            {{ $t('my-account.views.plan-invoices.next-charge') }}
            <span id="next-charge" class="font-weight-bold">{{ nextCharge }}</span>
          </div>
        </div>
        <hr class="w-100" />
        <div>
          <label for="recipes-year">
            {{ $t('my-account.views.plan-invoices.year') }}
          </label>
          <hs-multiselect
            class="col-12 col-lg-3 px-0"
            v-model="yearSelected"
            id="recipes-year"
            :options="yearsOptions"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
          />
        </div>
        <PlanInvoicesTable :invoices="invoicesFiltererd" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import hsPageHeader from '@/components/_structures/PageHeader';
import PlanInvoicesTable from './components/PlanInvoicesTable';
import planService from '@/services/heimdall/newPlans';
import { format } from '@/shared/helpers/dates';

export default {
  components: { hsPageHeader, PlanInvoicesTable },
  data() {
    return {
      yearSelected: new Date().getFullYear(),
      invoices: [],
    };
  },
  async mounted() {
    this.invoices = await planService.getInvoices();
    if (!this.actualPlan) {
      await this.refreshActualPlan(this.selectedSchool);
    }
  },
  methods: {
    ...mapActions('plan', ['refreshActualPlan']),
    onClose() {
      this.$router.push({ name: 'MyAccountPlans' });
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    ...mapState('plan', ['actualPlan']),
    invoicesFiltererd() {
      if (this.invoices.length) {
        return this.invoices.filter(
          invoice =>
            new Date(invoice.date).getFullYear() === this.yearSelected && invoice.status === 'payment_confirmed'
        );
      }
      return [];
    },
    yearsOptions() {
      let years = new Set();
      this.invoices.forEach(invoice => years.add(new Date(invoice.date).getFullYear()));
      return Array.from(years).sort();
    },
    nextCharge() {
      return this.invoices.length ? format(this.invoices[0].date, 'DD MMM YYYY') : '';
    },
  },
};
</script>

<style lang="scss">
.hs-plan-downgrade {
  background: transparent;
}
@media screen and (min-width: $screen-lg) {
  .hs-plan-downgrade {
    background: white;
  }
}
</style>
